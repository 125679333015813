import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme: any) => ({
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
} as any));

interface Props {
    notLoggedInUserMessage: string;
    onOpenLogin: (e: any) => void;
}

const NotLoggedInUser = (props: Props) => {
    const classes: any = useStyles();

    return (
        <div>
            <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
                <Typography component="div" variant="h6" style={{ fontSize: 24, color: '#098363' }}>
                    {props.notLoggedInUserMessage}
                </Typography>
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={(e) => { props.onOpenLogin(e) }}
                        style={{ background: "linear-gradient(90deg, #0FC593 0%, #098363 100%)" }}
                    >
                        <Typography variant={"h6"} style={{ fontSize: 16, fontWeight: 700 }}>
                            {"Вход / Регистрация"}
                        </Typography>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default NotLoggedInUser;
