import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';

const buttonStyle = {
    margin: '1rem',
    padding: '0.5rem 2rem',
    borderColor: 'green',
    borderRadius: 30,
    borderStyle: 'solid',
    borderWidth: 1,
    cursor: 'pointer'
}

interface Props {
    onBegin: () => void;
}

const TestInfo = (props: Props) => {

    return (
        <div>
            <Typography>
                {'Тестът се състои от 10 въпроса. Начлото на теста е момента на избира на бутон "Начало". Общото време за изпълнение на теста е 10 минути. Независимо дали е отговорено на всички въпроси след изтичане на времето, тестът ще бъде прекратен, а резултатите ще бъдат записани и изпратени на потребителя на посочения от него e-mail. На всеки въпрос са предложени 4 възможни отговора. Верният отговор се отбелязва и след това се избира бутон "следващ". За избран грешен или неизбран отговор не се получават токи. Не може да се връщате назад и да поправяте отговора на предишен въпрос. По всяко време можете да прекратите теста с избора на бутон "Прекрати". Независими дали е отговорено на всички въпроси резултатите ще бъдат записани и изпратени на посочения e-mail. Тестът е успешен при получени минимум 60 точки.'}
            </Typography>
            <div>
                <Divider style={{ background: '#0fc594' }} />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div></div>
                    <div style={buttonStyle} onClick={props.onBegin}>
                        <Typography variant={"h6"} style={{ fontSize: 18, color: '#098363', fontWeight: 700 }}>
                            {"Начало"}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TestInfo;
