import * as React from 'react';
import { useContext, useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { createStyles, Theme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../theming/theme';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center'
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            textAlign: 'center',
            display: 'block',
            whiteSpace: 'unset',
            textOverflow: 'unset'
        }
    }),
);

interface Props {
    content?: Array<string>;
    currentRoute: string;
    minHeight?: string;
}

const Header = (props: Props) => {
    const classes = useStyles();
    const [minHeight,] = useState(props.minHeight || '6rem');
    const [currentRoute, setCurrentRoute] = useState(props.currentRoute);

    useEffect(() => {
        setCurrentRoute(props.currentRoute);
    }, [props.currentRoute]);

    return (
        <>
            {
                currentRoute !== 'test'
                    ?
                    <div className={classes.root}>
                        <ThemeProvider theme={theme}>
                            <AppBar position="static" style={{
                                minHeight: minHeight, background: "linear-gradient(90deg, #098363 20%, #0FC593 100%)"
                            }}>
                                <Toolbar style={{ minHeight: '100%', justifyContent: 'center' }}>
                                    {props.content
                                        ?
                                        props.content.map((content, i) => (
                                            <Typography key={`component_key_${i}`} className={classes.title} variant="h4" noWrap>
                                                {content}
                                            </Typography>
                                        ))
                                        :
                                        null}
                                </Toolbar>
                            </AppBar>
                        </ThemeProvider>
                    </div>
                    :
                    null
            }
        </>

    )
}

export default Header;
