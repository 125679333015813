import * as React from 'react';
import { useEffect } from 'react';
import { RouteComponentProps, NavigateFn } from '@reach/router';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import InfoButton from '../../assets/information-button.svg';
import CertificateRequest from './CertificateRequest';
import { IUserData } from "../../context/AppUserContext";


const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        minWidth: 100,
        borderRadius: 15,
        filter: 'drop-shadow(0 0 7.5px rgba(0,0,0,0.15))',
        backgroundColor: '#ffffff',
        borderColor: '#ffffff',
        [theme.breakpoints.up('sm')]: {
            margin: '1rem',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '1rem 0px'
        },
    },
    certificateInfo: {
        textAlign: 'justify',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
            padding: '1rem',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '1rem 0px'
        },
    },
    infoText: {
        fontSize: 16,
        width: '85%',
        [theme.breakpoints.up('sm')]: {
            padding: '1rem',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '1rem 0px'
        },
    }
}));

type Props = RouteComponentProps & {
    changeCurrentRoute: (currPath: string) => void;
    userData: IUserData;
    navigate: NavigateFn;
}


const CertificatesContainer = (props: Props) => {
    const classes = useStyles();

    useEffect(() => {
        if (window.scrollTo) {
            window.scrollTo(0, 0);
        }
        props.changeCurrentRoute('certificate');
    }, []);

    const onUserSignIn = () => {
        if (Object.keys(props.userData).length) {
            alert('Вече сте влезли в системата!');
        } else {
            props.navigate("/sign-up/");
        }
    }

    return (
        <div style={{ marginTop: '5%' }}>
            <Container component="main" maxWidth="lg">
                <Card className={classes.root} variant="outlined">
                    <CardContent style={{ padding: 0 }}>
                        <div className={classes.certificateInfo}>
                            <img src={InfoButton} style={{ width: '8rem', height: '8rem' }} />
                            <Typography variant={"body1"} className={classes.infoText}>
                                {"Удостоверението е документ, който удостоверява пред АГКК преминат курс за поддържане и повишаване на квалификацията на правоспособното лице. Удостоверение могат да получат само правоспособните лица, които 1.) са преминали присъствен курс на обучение или 2.) успешно са преминали официален онлайн тест (виж страницата материали). Удостоверението може да се заяви онлайн, като попълните формата по-долу. Удостоверение може да се заяви от правоспособно лице регистрирано в платформата."}
                                {"Ако не сте регистриран, можете да се регистрирате "}
                                <span style={{
                                    fontSize: 18,
                                    color: 'rgb(68, 114, 196)',
                                    textDecoration: 'underline',
                                    cursor: 'pointer'
                                }} onClick={onUserSignIn}>{"тук"}</span>
                                {". Удостоверението се издава в цифров вид, в PDF формат или се разпечатва и се изпраща на посочен от вас адрес. Пощенските разходи са за сметка на получателя."}
                            </Typography>
                        </div>
                    </CardContent>
                </Card>
                <CertificateRequest userData={props.userData} />
            </Container>
        </div>
    )
}

export default CertificatesContainer;
