import * as React from 'react';
import { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { FormControlLabel, MenuItem, RadioGroup, Divider } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { green } from '@material-ui/core/colors';
import GreenRadio from '../Forms/GreenRadio';
import { IUserData } from '../../context/AppUserContext';
import Loading from '../Loading/Loading';
import AppContext from '../../context/AppContext';
import { appConfig } from '../../appConfig';
import NotLoggedInUser from '../NotLoggedInUser';


const useStyles = makeStyles((theme: Theme) => createStyles({
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    certRequest: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#f6fffd',
        [theme.breakpoints.up('sm')]: {
            margin: '2rem',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '1rem 0px'
        },
    },
    certRequestInput: {
        [theme.breakpoints.up('sm')]: {
            padding: '1rem'
        },
        [theme.breakpoints.down('sm')]: {
            padding: '1rem 0px'
        },
    }
} as any));

const incorrectInput = "Полето е задължително";
const incorrectEmail = "Невалиден e-mail";
const meilRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


interface Props {
    userData: IUserData;
}

const CertificateRequest = (props: Props) => {
    const classes: any = useStyles();

    const [email, setEmail] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [contactAddress, setContactAddress] = useState('');
    const [typeOfCertificate, setTypeOfCertificate] = useState('none');
    const [userName, setUserName] = useState('');
    const [userId, setUserId] = useState('');
    const [selectedValue, setSelectedValue] = useState<'email' | 'address'>('email');
    const [egn, setEgn] = useState('');
    const [birthPlace, setBirthPlace] = useState('');
    const [showLoading, setShowLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const [validEmail, setValidEmail] = useState(true);
    const [incorrectMeilFormat, setIncorrectMeilFormat] = useState(false);
    const [incorrectContactEmeilFormat, setIncorrectContactEmeilFormat] = useState(false);
    const [validTypeOfCertificate, setValidTypeOfCertificate] = useState(true);
    const [validContactEmail, setValidContactEmail] = useState(true);
    const [validContactAddress, setValidContactAddress] = useState(true);

    const [validEgn, setValidEgn] = useState(true);
    const [validBirthPlace, setValidBirthPlace] = useState(true);


    const [showError, setShowError] = useState(false);

    const [, setIsOpened] = useContext(AppContext);

    useEffect(() => {
        const _userData = props.userData;
        if (Object.keys(_userData).length) {
            const name = _userData.firstName;
            const lastName = _userData.lastName;
            const _userId = _userData.userId;
            const userEmail = _userData.email;
            setUserName(`${name} ${lastName}`);
            setUserId(_userId);
            setEmail(userEmail);
            setContactEmail(userEmail);
        }
    }, [props.userData]);

    useEffect(() => {
        if (email) {
            setValidEmail(true);
        }
        if (typeOfCertificate !== 'none') {
            setValidTypeOfCertificate(true);
        }
        if (contactEmail) {
            setValidContactEmail(true);
        }
        if (contactAddress) {
            setValidContactAddress(true);
        }
        if (egn) {
            setValidEgn(true);
        }
        if (birthPlace) {
            setValidBirthPlace(true);
        }

    }, [email, typeOfCertificate, contactEmail, contactAddress, birthPlace, egn]);

    const onCertificateRequest = (e: any) => {

        if (!email) {
            setValidEmail(false);
            return;
        }

        if (!egn) {
            setValidEgn(false);
            return;
        }
        if (!birthPlace) {
            setValidBirthPlace(false);
            return;
        }

        if (typeOfCertificate === 'none') {
            setValidTypeOfCertificate(false);
            return;
        }
        if (selectedValue === 'email' && !contactEmail) {
            setValidContactEmail(false);
            return;
        }
        if (selectedValue === 'address' && !contactAddress) {
            setValidContactAddress(false);
            return;
        }

        if (!(meilRegex.test(String(email).toLowerCase()))) {
            setIncorrectMeilFormat(true);
            return;
        }

        if (selectedValue === 'email' && !(meilRegex.test(String(contactEmail).toLowerCase()))) {
            setIncorrectContactEmeilFormat(true);
            return;
        }

        e.preventDefault();
        setShowLoading(true);
        axios.post(appConfig.certificateRequestUrl, {
            email: email,
            type_of_certificate: typeOfCertificate,
            type_of_delivery: selectedValue === 'email' ? 'E-mail' : 'Адрес, на който да се получи хартиено копие',
            delivery_details: selectedValue === 'email' ? contactEmail : contactAddress,
            user_name: userName,
            user_id: userId,
            birth_place: birthPlace,
            egn: egn,
        }, {
            headers: { 'Authorization': `Token ${props.userData.appKey}` }
        }
        ).then(r => {
            // const resultData = r.data;
            setShowLoading(false);
            setShowSuccess(true);
        }).catch(err => {
            console.error(err);
            setShowLoading(false);
            setShowError(true);
            if (window.scrollTo) {
                window.scrollTo(0, 0);
            }
        })
    }

    const onOpenLogin = (e: any) => {
        e.preventDefault();
        setIsOpened(true);
    }

    return (
        <div className={classes.certRequest}>
            {
                showLoading
                    ?
                    <div style={{ height: '30rem', display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                        <Loading style={{ width: 'unset', height: 'unset', textAlign: 'center' }} show={showLoading} />
                    </div>
                    :
                    showSuccess
                        ?
                        <div style={{ textAlign: 'center' }}>
                            <div>
                                <CheckCircleRoundedIcon style={{ width: '15rem', height: '15rem', color: green[500] }} />
                            </div>
                            <Typography variant={"h6"} style={{ fontSize: 18, color: '#098363', fontWeight: 700 }}>
                                {`Успешна заявка за удостоверение!`}
                            </Typography>
                        </div>
                        :
                        Object.keys(props.userData).length ?
                            <>
                                <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
                                    <Typography component="div" variant="h6" style={{ fontSize: 24, color: '#098363' }}>
                                        {"Заявка за получаване на удостоверение"}
                                    </Typography>
                                </div>
                                {
                                    showError
                                        ?
                                        <div style={{ color: 'red', width: '100%', textAlign: 'center' }}>{"Възникна грешка. Моля, опитайте отново!"}</div>
                                        :
                                        null
                                }
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '70%' }}>
                                    <Grid item xs={12} sm={12} className={classes.certRequestInput}>
                                        <Typography variant="h6" gutterBottom style={{ fontWeight: 700, fontSize: 14 }}>
                                            {"Име"}
                                        </Typography>
                                        <TextField
                                            size={"small"}
                                            value={userName}
                                            name="userName"
                                            variant="outlined"
                                            disabled={true}
                                            fullWidth
                                            id="userName"
                                            label=""
                                            style={{ background: '#F5F5F5' }}
                                        />
                                    </Grid>
                                    <Divider style={{ background: 'rgba(9, 131, 99, 0.3)', }} />
                                    <Grid item xs={12} sm={12} className={classes.certRequestInput}>
                                        <Typography variant="h6" gutterBottom style={{ fontWeight: 700, fontSize: 14 }}>
                                            {"Номер от регистъра на правоспособните физически лица по кадастър"}
                                        </Typography>
                                        <TextField
                                            size={"small"}
                                            value={userId}
                                            name="userId"
                                            variant="outlined"
                                            disabled={true}
                                            required
                                            fullWidth
                                            id="userId"
                                            label=""
                                            style={{ background: '#F5F5F5' }}
                                        />
                                    </Grid>
                                    <Divider style={{ background: 'rgba(9, 131, 99, 0.3)', }} />
                                    <Grid item xs={12} sm={12} className={classes.certRequestInput}>
                                        <Typography variant="h6" gutterBottom style={{ fontWeight: 700, fontSize: 14 }}>
                                            {"E-mail *"}
                                        </Typography>
                                        <TextField
                                            size={"small"}
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            autoComplete="email"
                                            name="userEmail"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="userEmail"
                                            label=""
                                            style={{ background: 'white' }}
                                            error={!validEmail || incorrectMeilFormat ? true : false}
                                            helperText={!validEmail ? incorrectInput : incorrectMeilFormat ? incorrectEmail : ''}
                                        />
                                        <Typography variant="body1" style={{ fontSize: 12, fontWeight: 400 }}>
                                            {"Моля, въведете коректен e-mail адрес за връзка с Вас"}
                                        </Typography>
                                    </Grid>
                                    <Divider style={{ background: 'rgba(9, 131, 99, 0.3)', }} />
                                    <Grid item xs={12} sm={12} className={classes.certRequestInput}>
                                        <Typography variant="h6" gutterBottom style={{ fontWeight: 700, fontSize: 14 }}>
                                            {"ЕГН *"}
                                        </Typography>
                                        <TextField
                                            size={"small"}
                                            value={egn}
                                            onChange={(e) => setEgn(e.target.value)}
                                            autoComplete="egn"
                                            name="egn"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="egn"
                                            label=""
                                            style={{ background: 'white' }}
                                            error={!validEgn ? true : false}
                                            helperText={!validEgn ? incorrectInput : ''}
                                        />
                                    </Grid>
                                    <Divider style={{ background: 'rgba(9, 131, 99, 0.3)', }} />
                                    <Grid item xs={12} sm={12} className={classes.certRequestInput}>
                                        <Typography variant="h6" gutterBottom style={{ fontWeight: 700, fontSize: 14 }}>
                                            {"Място на раждане *"}
                                        </Typography>
                                        <TextField
                                            size={"small"}
                                            value={birthPlace}
                                            onChange={(e) => setBirthPlace(e.target.value)}
                                            autoComplete="email"
                                            name="birthPlace"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="birthPlace"
                                            label=""
                                            style={{ background: 'white' }}
                                            error={!validBirthPlace ? true : false}
                                            helperText={!validBirthPlace ? incorrectInput : ''}
                                        />
                                    </Grid>
                                    <Divider style={{ background: 'rgba(9, 131, 99, 0.3)', }} />
                                    <Grid item xs={12} sm={12} className={classes.certRequestInput}>
                                        <Typography variant="h6" gutterBottom style={{ fontWeight: 700, fontSize: 14 }}>
                                            {"Форма на удостоверение"}
                                        </Typography>
                                        <FormControl fullWidth style={{ marginTop: '0.5rem' }}>
                                            {/* <InputLabel id="simple-course-type" /> */}
                                            <Select
                                                variant="outlined"
                                                fullWidth
                                                labelId="simple-course-type"
                                                id="simple-course-type-select"
                                                value={typeOfCertificate}
                                                onChange={(e) => { setTypeOfCertificate(e.target.value as string) }}
                                                style={{ background: 'white' }}
                                                inputProps={{ size: 'small' }}
                                                error={!validTypeOfCertificate ? true : false}
                                            >
                                                <MenuItem value={"none"}>{"Изберете"}</MenuItem>
                                                <MenuItem value={"Цифров вид в PDF"}>{"Цифров вид в PDF"}</MenuItem>
                                                <MenuItem value={"Хартиено копие"}>{"Хартиено копие"}</MenuItem>
                                            </Select>
                                            {
                                                !validTypeOfCertificate ?
                                                    <div style={{ color: 'red' }}>{incorrectInput}</div> :
                                                    null
                                            }
                                        </FormControl>
                                    </Grid>
                                    <Divider style={{ background: 'rgba(9, 131, 99, 0.3)', }} />
                                    <Grid item xs={12} sm={12} className={classes.certRequestInput}>
                                        <Typography variant="h6" gutterBottom style={{ fontWeight: 700, fontSize: 14 }}>
                                            {"Начин на получаване"}
                                        </Typography>
                                        <div style={{ minHeight: '15rem' }}>
                                            <RadioGroup row aria-label="position" name="position" defaultValue="top" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <FormControlLabel
                                                    value="top"
                                                    control={
                                                        <GreenRadio
                                                            checked={selectedValue === 'email'}
                                                            onChange={() => setSelectedValue('email')}
                                                            value="email"
                                                            name="radio-button-email"
                                                        // inputProps={{ 'aria-label': 'C' }}
                                                        />
                                                    }
                                                    label="E-mail"
                                                // labelPlacement="top"
                                                />
                                                <FormControlLabel
                                                    value="top"
                                                    control={
                                                        <GreenRadio
                                                            checked={selectedValue === 'address'}
                                                            onChange={() => setSelectedValue('address')}
                                                            value="address"
                                                            name="radio-button-address"
                                                        // inputProps={{ 'aria-label': 'C' }}
                                                        />
                                                    }
                                                    label="Адрес, на който да се получи хартиено копие"
                                                // labelPlacement="top"
                                                />
                                            </RadioGroup>
                                            <div>
                                                {
                                                    selectedValue === 'email'
                                                        ?
                                                        <TextField
                                                            size={"small"}
                                                            value={contactEmail}
                                                            onChange={(e) => setContactEmail(e.target.value)}
                                                            autoComplete="email"
                                                            name="contactEmail"
                                                            variant="outlined"
                                                            required
                                                            fullWidth
                                                            id="contactEmail"
                                                            label="E-mail"
                                                            style={{ background: 'white' }}
                                                            error={(selectedValue === 'email') && (!validContactEmail || incorrectContactEmeilFormat) ? true : false}
                                                            helperText={selectedValue === 'email' && !validContactEmail ? incorrectInput : incorrectContactEmeilFormat ? incorrectEmail : ''}
                                                        />
                                                        :
                                                        <>
                                                            <Typography variant="h6" gutterBottom style={{ fontWeight: 700, fontSize: 14 }}>
                                                                {"Посочете адрес, където да бъде изпратено хартиено копие"}
                                                            </Typography>
                                                            <TextField
                                                                key={"addressForCertificate"}
                                                                value={contactAddress}
                                                                onChange={(e) => setContactAddress(e.target.value)}
                                                                autoComplete="address"
                                                                name="address"
                                                                variant="outlined"
                                                                multiline
                                                                minRows={2}
                                                                maxRows={2}
                                                                fullWidth
                                                                id="address"
                                                                label=""
                                                                error={selectedValue === 'address' && !validContactAddress ? true : false}
                                                                helperText={selectedValue === 'address' && !validContactAddress ? incorrectInput : ''}
                                                                style={{ background: 'white' }}
                                                            />
                                                        </>
                                                }
                                            </div>
                                        </div>
                                        <Divider style={{ background: 'rgba(9, 131, 99, 0.3)', }} />
                                    </Grid>
                                </div>
                                <div style={{ width: '100%', textAlign: 'center' }}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        onClick={(e) => { onCertificateRequest(e) }}
                                        style={{ background: "linear-gradient(90deg, #0FC593 0%, #098363 100%)" }}
                                    >
                                        <Typography variant={"h6"} style={{ fontSize: 16, fontWeight: 700 }}>
                                            {"Заяви удостоверение"}
                                        </Typography>
                                    </Button>
                                </div>
                            </>
                            :
                            <NotLoggedInUser onOpenLogin={onOpenLogin} notLoggedInUserMessage={"За да заявите Вашето удостоверение, първо трябва да влезете в системата!"} />

            }

        </div>
    )
}

export default CertificateRequest;
