import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { IUserData } from '../../context/AppUserContext';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ForumComment from './ForumComment';
import { Card, CardContent, CardActions, Button } from '@material-ui/core';
import AccordionSummary from '../Accordion/AccordionSummary';
import Accordion from '../Accordion/Accordion';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import AccordionDetails from '../Accordion/AccordionDetails';
import axios from 'axios';
import { appConfig } from 'src/appConfig';
import { showAllTextCss } from '../utils';
import TextField from '@material-ui/core/TextField';


interface Props extends RouteComponentProps {
    changeCurrentRoute: (currPath: string) => void;
    userData: IUserData;
}

interface IForumPost {
    created_at: string
    desc: string
    id: string
    title: string
    user: string
}

interface IComment {
    created_at: string
    desc: string
    forum: number
    id: number
    user: string
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(1.5)',
        color: '#0fc594'
    },
    desktopNews: {
        [theme.breakpoints.up('sm')]: {
            minHeight: '100%',
            marginLeft: 'auto'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    mobileNews: {
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
    },
    separator: {
        marginLeft: '1rem',
        marginRight: '1rem'
    },
    root: {
        minWidth: 275,
        borderRadius: 15,
        filter: 'drop-shadow(0 0 7.5px rgba(0,0,0,0.15))',
        backgroundColor: '#ffffff',
        borderColor: '#ffffff'
    },
    title: {
        fontSize: 18,
        fontWeight: 700,
        marginBottom: '1rem'
    },

}));

const ForumContainer = (props: Props) => {
    const classes = useStyles();
    const bull = <span className={classes.bullet}>•</span>;

    const [forumPosts, setForumPosts] = useState([]);
    const [selectedPost, setSelectedPost] = useState<IForumPost>({
        created_at: '',
        desc: '',
        id: '',
        title: '',
        user: '',
    });
    const [comments, setComments] = useState<Array<IComment>>([]);
    const [isAddingPost, setIsAddingPost] = useState(false);
    const [postTitle, setPostTitle] = useState('');
    const [postDescription, setPostDescription] = useState('');
    const [isCommenting, setIsCommenting] = useState(false);
    const [commentText, setCommentText] = useState('');

    useEffect(() => {
        if (window.scrollTo) {
            window.scrollTo(0, 0);
        }
        props.changeCurrentRoute(props.path);
        getQuestions();
    }, []);

    const getQuestions = () => {
        axios.get(appConfig.forumQuestions, {
            params: {
                count: 10,
                offset: 0,
            },
            headers: {
                'Authorization': `Token ${props.userData.appKey}`
            }
        }).then(r => {
            const data = r.data;
            // const commentsQueries = [];
            // const questionResults: Array<IAppForumPost> = [];

            setForumPosts(data);
            forumPostChanged(data[0]);
            // if (data && data.length) {

            // }

        }).catch(err => {
            console.error(err);
        })
    }

    const forumPostChanged = (post: IForumPost) => {
        const copiedPost = { ...post };
        copiedPost.created_at = cleanDate(copiedPost.created_at);
        setSelectedPost(copiedPost);
        axios.get(appConfig.forumComments, {
            params: {
                questionId: post.id,
            },
            headers: {
                'Authorization': `Token ${props.userData.appKey}`
            }
        }).then(commentsData => {
            const comments = commentsData.data;
            setComments(comments);
        }).catch(err => {
            console.error(err);
        });
    }

    const cleanDate = (uncleanedDate: string) => {
        if (uncleanedDate && uncleanedDate.indexOf(' ') !== -1) {
            const splittedDate = uncleanedDate.split(' ')
            return splittedDate[0];
        }
        if (uncleanedDate && uncleanedDate.indexOf('T') !== -1) {
            const splittedDate = uncleanedDate.split('T')
            return splittedDate[0];
        }
        return uncleanedDate;
    }

    const addPost = () => {
        axios.post(appConfig.addQuestionUrl, {
            user: props.userData.user_pk,
            title: postTitle,
            desc: postDescription,
        }, {
            headers: { 'Authorization': `Token ${props.userData.appKey}` }
        }).then(r => {
            const copiedForumPsots = [...forumPosts];
            copiedForumPsots.unshift({
                created_at: cleanDate(r.data.created_at),
                desc: postDescription,
                id: r.data.id,
                title: postTitle,
                user: `${props.userData.firstName} ${props.userData.lastName}`,
                comments: [],
            });
            // copiedForumPsots = copiedForumPsots.reverse();
            setForumPosts(copiedForumPsots);
            setSelectedPost(copiedForumPsots[0]);
            setIsAddingPost(false);
            setPostTitle('');
            setPostDescription('');
        }).catch(err => {
            console.error(err);
            setIsAddingPost(false);
            alert('Възникна грешка! Моля, опитайте по-късно!');
        });
    }

    const addComment = () => {
        axios.post(appConfig.addCommentUrl, {
            desc: commentText,
            forum: selectedPost.id,
            user: props.userData.user_pk,
        },
            {
                headers: { 'Authorization': `Token ${props.userData.appKey}` }
            }).then(r => {
                const copiedComments = [...comments];
                copiedComments.push({
                    desc: commentText,
                    user: `${props.userData.firstName} ${props.userData.lastName}`,
                    created_at: r.data.created_at,
                    forum: r.data.forum,
                    id: r.data.id,
                });
                setComments(copiedComments);
                setIsCommenting(false);
                setCommentText('');

            }).catch(err => {
                setIsCommenting(false);
                console.error(err);
                alert('Възникна грешка! Моля, опитайте по-късно!')
            });
    }

    return (
        <div>
            <Container component="div" maxWidth="lg">
                <Grid container spacing={3} className={classes.desktopNews}>
                    <Grid item xs={12} sm={3}>
                        <div style={{ width: '100%', display: 'flex' }}>
                            {
                                props.userData.appKey
                                    ?
                                    <div style={{ marginTop: '4rem', paddingRight: '2rem' }}>
                                        <div style={{ margin: '1.5rem 0px', textAlign: 'center' }}>
                                            <Button style={{ minHeight: '2rem', minWidth: '5rem', background: '#2E8D71' }} size="large" onClick={() => { setIsAddingPost(!isAddingPost) }}>
                                                <Typography style={{ fontSize: 14, color: 'white' }} variant="body1" component="p">
                                                    {"Започни тема"}
                                                </Typography>
                                            </Button>
                                        </div>
                                        {
                                            forumPosts.length > 0
                                                ?
                                                forumPosts.map((post, i) => (
                                                    <>
                                                        <div key={`post_key_title_${i}`} style={{ display: 'flex', marginTop: '0.5rem', cursor: 'pointer' }} onClick={() => forumPostChanged(post)}>
                                                            {
                                                                (post.title === selectedPost.title) && !isAddingPost
                                                                    ?
                                                                    <div style={{ display: 'inline-block', marginRight: '0.5rem' }}>
                                                                        {bull}
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                            <div style={{ display: 'inline-block' }}>
                                                                <Typography variant={(post.title === selectedPost.title) && !isAddingPost ? 'h6' : 'body1'} style={{ fontSize: 14 }}>
                                                                    {post.title}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    </>
                                                ))
                                                :
                                                null
                                        }
                                    </div>
                                    :
                                    null
                            }
                            <Divider style={{ height: '80rem', marginTop: '2rem', marginBottom: '2rem' }} orientation={"vertical"} />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        {
                            isAddingPost
                                ?
                                <div style={{ margin: '1rem 0px' }}>
                                    <Card className={classes.root} variant="outlined">
                                        <CardContent>
                                            <div style={{ margin: '1rem 0px' }}>
                                                <TextField
                                                    value={postTitle}
                                                    onChange={(e) => setPostTitle(e.target.value)}
                                                    autoComplete="postTitle"
                                                    name="postTitle"
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    id="postTitle"
                                                    label="Заглавие"
                                                />
                                            </div>

                                            <div style={{ margin: '1rem 0px' }}>
                                                <TextField
                                                    value={postDescription}
                                                    onChange={(e) => setPostDescription(e.target.value)}
                                                    autoComplete="postDescription"
                                                    name="postDescription"
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    multiline
                                                    minRows={3}
                                                    id="postDescription"
                                                    label=""
                                                />
                                            </div>
                                        </CardContent>
                                        <CardActions style={{ marginLeft: 'auto', position: 'relative' }}>
                                            <Button style={{ marginLeft: 'auto', padding: '4px 17px' }} size="small" onClick={() => setIsAddingPost(false)}>
                                                <Typography style={{ fontSize: 14, color: '#2E8D71' }} variant="body1" component="p">
                                                    {"Откажи"}
                                                </Typography>
                                            </Button>
                                            <Button style={{ padding: '4px 17px' }} size="small" onClick={addPost}>
                                                <Typography style={{ fontSize: 14, color: '#2E8D71' }} variant="body1" component="p">
                                                    {"Добави"}
                                                </Typography>
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </div>

                                :
                                null
                        }
                        {
                            selectedPost && !isAddingPost
                                ?
                                <div style={{ marginTop: '4rem' }}>
                                    <Card className={classes.root} variant="outlined">
                                        <CardContent>
                                            {
                                                props.userData.appKey
                                                    ?
                                                    <>
                                                        <Typography variant="body1" component="div" style={{ fontSize: 16, fontWeight: 700, marginBottom: '1rem' }}>
                                                            <span style={{ fontWeight: 700 }}>
                                                                {selectedPost.title}
                                                            </span>
                                                        </Typography>
                                                        <div style={{ display: 'flex', marginBottom: '1rem', alignItems: 'center' }}>
                                                            <Typography variant="body1" component="div" style={{ fontSize: 16, fontWeight: 400, textAlign: 'center' }}>
                                                                <span style={{ fontWeight: 700, color: '#098363' }}>
                                                                    {selectedPost.user}
                                                                </span>
                                                            </Typography>
                                                            <Typography className={classes.separator} variant="body2" component="p">
                                                                {'|'}
                                                            </Typography>
                                                            <Typography variant="body1" component="div" style={{ fontSize: 16, fontWeight: 400, textAlign: 'center' }}>
                                                                <span style={{ fontWeight: 700, color: '#098363' }}>
                                                                    {selectedPost.created_at}
                                                                </span>
                                                            </Typography>
                                                        </div>
                                                        <Typography variant="body1" component="p" style={showAllTextCss}>
                                                            {selectedPost.desc}
                                                        </Typography>
                                                        <Divider style={{ width: '100%', marginTop: '0.5rem', marginBottom: '0.5rem', background: '#E0F7F2' }} />
                                                        <>
                                                            {comments.length
                                                                ?
                                                                comments.map((c, j) => (
                                                                    <ForumComment key={`comment_${j}`} description={c.desc} user={`${c.user}`} />
                                                                ))
                                                                : null}
                                                        </>
                                                    </>
                                                    :
                                                    <Typography variant="body1" component="p" style={showAllTextCss}>
                                                        {"Необходима е регистрация, за да достъпите форума."}
                                                    </Typography>
                                            }
                                        </CardContent>
                                        {
                                            isCommenting
                                                ?
                                                <div style={{ padding: '1rem 2rem' }}>
                                                    <TextField
                                                        value={commentText}
                                                        onChange={(e) => setCommentText(e.target.value)}
                                                        autoComplete="commentText"
                                                        multiline
                                                        minRows={2}
                                                        name="commentText"
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        id="commentText"
                                                        label=""
                                                    />
                                                </div>
                                                :
                                                null
                                        }
                                        <CardActions style={{ marginLeft: 'auto', position: 'relative' }}>
                                            {
                                                props.userData.appKey
                                                    ?
                                                    <>
                                                        <Button style={{ marginLeft: 'auto', padding: '4px 17px' }} size="small" onClick={() => { setIsCommenting(!isCommenting) }}>
                                                            <Typography style={{ fontSize: 14, color: '#2E8D71' }} variant="body1" component="p">
                                                                {isCommenting ? "Откажи" : "Добави коментар"}
                                                            </Typography>
                                                        </Button>
                                                        {
                                                            isCommenting
                                                                ?
                                                                <Button style={{ padding: '4px 17px' }} size="small" onClick={addComment}>
                                                                    <Typography style={{ fontSize: 14, color: '#2E8D71' }} variant="body1" component="p">
                                                                        {"Коментирай"}
                                                                    </Typography>
                                                                </Button>
                                                                :
                                                                null
                                                        }
                                                    </>
                                                    :
                                                    null
                                            }
                                        </CardActions>
                                    </Card>
                                </div>
                                :
                                null
                        }
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default ForumContainer;
