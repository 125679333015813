import * as React from 'react';
import { useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import axios from 'axios';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import News from './News';
import { INewsItem } from './INewsInterfaces';
import { appConfig } from '../../appConfig';
import Loading from '../Loading/Loading';


const useStyles = makeStyles((theme: Theme) => createStyles({
    news: {
        [theme.breakpoints.up('md')]: {
            width: '75%',
        },
        [theme.breakpoints.down('md')]: {
            width: '75%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    }
}));

const NewsContainer = () => {
    const classes = useStyles();

    const [newsItems, setNewsItems] = useState<Array<INewsItem>>([]);
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        getNewsRequest(3, 0);
    }, []);


    const getNewsRequest = (count: number, offset: number) => {
        setShowLoading(true);
        axios.get(appConfig.allNews, {
            params: {
                count,
                offset
            }
        }).then(r => {
            const resultNewsData = r.data;
            const newsDataFormatted = resultNewsData.reduce((acc: Array<INewsItem>, curr: INewsItem) => {
                const currentDate = curr['create_date'];
                const currentDateSplitted = currentDate.split('-');
                curr['create_date'] = `${currentDateSplitted[2]}/${currentDateSplitted[1]}/${currentDateSplitted[0]}`;
                acc.push(curr);
                return acc;
            }, [] as Array<INewsItem>);
            const newsCount = newsDataFormatted.length;
            let calculatedPageCount = 0;
            if (newsCount) {
                if (newsCount < 5) {
                    calculatedPageCount = 1;
                } else {
                    calculatedPageCount = Math.floor(newsCount / 5);
                }
            }
            setShowLoading(false);
            setNewsItems(newsDataFormatted);
        }).catch(err => {
            setShowLoading(false);
            console.error(err);
        });
    }

    return (
        <Container component="div" maxWidth="lg" style={{ height: '100%' }}>
            <Typography style={{ fontSize: 24, color: '#098363', fontWeight: 700, textAlign: 'center' }} variant="h6" component="div">
                {"Новини"}
            </Typography>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className={classes.news}>
                    {
                        showLoading
                            ?
                            <div style={{ height: '30rem', display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <Loading style={{ width: 'unset', height: 'unset', textAlign: 'center' }} show={showLoading} />
                            </div>
                            :
                            newsItems.length
                                ?
                                newsItems.map((newItem, i) => (
                                    <News key={`news_${i}`} title={newItem.title} date={newItem.create_date} description={newItem.content} />
                                ))
                                :
                                "Не са намерени новини!"
                    }

                    {/* <News title={'Национално съвещание по фотограметрия и дистанционни изследвания'} date={'06/07/2021'} description={'Правоспособните лица завършват курса на обучение с издаване на удостоверение, което показва успешно преминаване на курса. Удостоверението се получава след успешно преминат тест'} />
                    <News title={'Курсове за повишаване на квалификацията на правоспособните лица по кадастъра'} date={'06/07/2021'} description={'Правоспособните лица завършват курса на обучение с издаване на удостоверение, което показва успешно преминаване на курса. Удостоверението се получава след успешно преминат тест'} />
                    <News title={'"Copernicus Masters" и "Galileo Masters" 2021'} date={'06/07/2021'} description={'Правоспособните лица завършват курса на обучение с издаване на удостоверение, което показва успешно преминаване на курса. Удостоверението се получава след успешно преминат тест'} /> */}
                </div>
            </div>
        </Container>
    )
}

export default NewsContainer;
