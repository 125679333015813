import { createStyles, Theme } from '@material-ui/core/styles';


export const testStyles = (theme: Theme) => createStyles({
    question: {
        [theme.breakpoints.up('sm')]: {
            padding: '2rem'
        },
        [theme.breakpoints.down('sm')]: {
            padding: 0
        },
    },
    questionContainer: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            padding: '2rem 1rem'
        },
        [theme.breakpoints.down('sm')]: {
            padding: 0
        },
    },
    questionsDivider: {
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'inherit'
        },
    }
})