import { createStyles, Theme } from '@material-ui/core/styles';



const drawerWidth = 240;

export const headerStyles = (theme: Theme) => createStyles({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        position: 'absolute',
        right: 0,
    },
    title: {
        flexGrow: 1,
        display: 'block',
        color: '#098363',
        paddingLeft: '0.2rem'
    },
    linksContainer: {
        width: '67rem',
        position: 'relative',
        minWidth: 465,
        // borderRadius: theme.shape.borderRadius,
        // backgroundColor: alpha(theme.palette.common.white, 0.15),
        // '&:hover': {
        //     backgroundColor: alpha(theme.palette.common.white, 0.25),
        // },
        marginLeft: 0,
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            // width: 'auto',
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: theme.spacing(1),
            // width: 'auto',
            width: '61rem',
        },
    },
    linkButtons: {
        display: 'flex',
        // justifyContent: 'space-evenly',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    userIcon: {
        color: '#098363',
        height: '2rem',
        width: '2rem'
    },
    desktopToolbar: {
        [theme.breakpoints.up('sm')]: {
            minHeight: '100%',
            marginLeft: 'auto'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    mobileToolbar: {
        [theme.breakpoints.down('sm')]: {
            color: 'inherit'
        },
    },
    hide: {
        display: 'none',
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        [theme.breakpoints.down('md')]: {
            backgroundColor: 'transparent'
        },
    },
    linkButton: {
        textDecoration: 'none !important',
        [theme.breakpoints.down('sm')]: {
            margin: '1rem',
            textDecoration: 'none !important',
        },
    },
    userButton: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            margin: '1rem',
        },
    },
    closeMenuButton: {
        margin: '1rem',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
    }
});
