import * as React from 'react';
import sgzb from '../../assets/logo-sgzb.png';
import uaceg from '../../assets/logo_UASG.png';
import mgu from '../../assets/p_10_1.png';
import kiip from '../../assets/kiip.png';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) => createStyles({
    courseInformationText: {
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            padding: '3rem 4rem'
        },
        [theme.breakpoints.down('md')]: {
            padding: '2rem 3rem'
        },
        [theme.breakpoints.down('sm')]: {
            padding: '1rem 2rem'
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0.5rem 0.8rem'
        },
    }
}));


const Organizations = () => {
    const classes = useStyles();

    return (
        <div className={classes.courseInformationText}>
            <Typography component="div" variant="body1" style={{ textAlign: 'center', width: '80%', display: 'inline-block' }}>
                {"Курсът на обучение и материалите са разработени по одобрена от АГКК програма за поддържане и повишаване квалификацията на правоспособните лица в съответствие с чл. 20, ал. 4 от ЗКИР."}
            </Typography>
            <Typography component="div" variant="body1" style={{ textAlign: 'center', marginBottom: '2rem', width: '70%', display: 'inline-block' }}>
                {"Програмата за обучение и предоставените материали са разработени със съдействието на:"}
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                <a href={"https://geodesy-union.org/"} target={"__blank"} style={{ padding: '1rem' }}>
                    <img src={sgzb} width={256} height={80} />
                </a>
                <a href={"https://uacg.bg/"} target={"__blank"} style={{ padding: '1rem' }}>
                    <img src={uaceg} width={170} height={74} />
                </a>
                <a href={"http://mgu.bg/new/index.php"} target={"__blank"} style={{ padding: '1rem' }}>
                    <img src={mgu} width={94} height={98} />
                </a>
                <a href={"https://kiip.bg/"} target={"__blank"} style={{ padding: '1rem' }}>
                    <img src={kiip} width={80} height={76} />
                </a>
            </div>
        </div>
    )
}

export default Organizations;
