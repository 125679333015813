import * as React from 'react';
import { useState, ChangeEvent, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import GreenRadio from '../Forms/GreenRadio';
import { testStyles } from './testStyles';


const useStyles = makeStyles(testStyles);

interface Props {
    questionNumber: number;
    question: string;
    answers: Array<string>;
    images?: Array<string>;
    handleChange: (naswer: number) => void;
}

const Question = (props: Props) => {
    const classes = useStyles();

    const [questionNumber, setQuestionNumber] = useState(props.questionNumber);
    const [question, setQuestion] = useState(props.question);
    const [answers, setAnswers] = useState(props.answers);
    const [images, setImages] = useState(props.images);

    const [selectedValue, setSelectedValue] = useState(0);

    useEffect(() => {
        setQuestionNumber(props.questionNumber);
        setSelectedValue(0);
    }, [props.questionNumber]);

    useEffect(() => {
        setQuestion(props.question);
        setSelectedValue(0);
    }, [props.question]);

    useEffect(() => {
        setAnswers(props.answers);
        setSelectedValue(0);
    }, [props.answers]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const answer = +event.target.value;
        setSelectedValue(answer);
        props.handleChange(answer);
    };

    return (
        <div className={classes.question}>
            <div>
                <Typography variant={"body1"} style={{ fontSize: 14 }}>
                    {`Въпрос № ${questionNumber}`}
                </Typography>
            </div>
            <div style={{ padding: '1rem 0px' }}>
                <Typography variant={"h6"} style={{ fontSize: 18, fontWeight: 700, color: '#161616' }}>
                    {`${question}`}
                </Typography>
            </div>
            {
                images
                    ?
                    images.map((image, j) => (
                        <div key={`answer_image_${j}`}>
                            <img src={image} />
                        </div>
                    ))
                    :
                    null
            }
            <div className={classes.questionContainer}>
                <FormControl component="fieldset">
                    <RadioGroup aria-label="gender" name="gender1" value={selectedValue} onChange={handleChange}>
                        {answers.map((answer, i) => (
                            <>
                                <div key={`answer_${i}`} style={{ display: 'flex', alignItems: 'center' }}>
                                    {/* <GreenRadio
                            checked={selectedValue === i}
                            onChange={handleChange}
                            value={i}
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': `${i}` }}
                        />
                        <div style={{ padding: '0.7rem 0.5rem' }}>
                            <Typography>
                                {answer}
                            </Typography>
                        </div> */}
                                    <FormControlLabel value={i} control={<GreenRadio
                                        checked={selectedValue === (i + 1)}
                                        // onChange={handleChange}
                                        value={i + 1}
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': `${i}` }}
                                        style={{ padding: '1rem' }}
                                    />} label={answer} />
                                </div>
                                {
                                    i !== answers.length - 1
                                        ?
                                        <Divider className={classes.questionsDivider} style={{ background: '#0fc594' }} />
                                        :
                                        null
                                }
                            </>
                        ))}
                    </RadioGroup>
                </FormControl>
            </div>
        </div>
    )
}

export default Question;
