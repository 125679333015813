import * as React from 'react';
import { useEffect, useState, FunctionComponent } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { green } from '@material-ui/core/colors';
import { RouteComponentProps } from '@reach/router';
import { theme } from '../../theming/theme';
import axios from 'axios';
import { Link } from '@reach/router';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { appConfig } from '../../appConfig';


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));


const incorrectInput = "Полето е задължително";
const incorrectRepeatPassowrdLabel = "Паролите не съвпадат";

type Props = RouteComponentProps;

const SetNewPassword = (props: Props) => {
    const classes = useStyles();

    const [studentsEmail, setStudentsEmail] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);

    const [token, setToken] = useState('');
    const [user, setUser] = useState('');

    const [validPassword, setValidPassword] = useState(true);
    const [validRepeatPassword, setValidRepeatPassword] = useState(true);
    const [incorrectRepeatPassowrd, setIncorrectRepeatPassowrd] = useState(false);

    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');

    const [showFailure, setShowFailure] = useState(false);

    useEffect(() => {
        let token = '';
        let user = '';
        const address = window.location.href;
        const splittedAddress = address.split('?');
        if (splittedAddress[1]) {
            const queries = splittedAddress[1].split('&');
            for (let i = 0; i < queries.length; i++) {
                const splittedParam = queries[i].split('=');
                if (splittedParam[0] === 'token') {
                    token = splittedParam[1];
                } else if (splittedParam[0] === 'user') {
                    user = splittedParam[1];
                }
            }
            if (!token || !user) {
                props.navigate('/');
            } else {
                setToken(token);
                setUser(user);
            }
        }

    }, []);


    const onNewPasswordSent = (e: any) => {
        e.preventDefault();

        if (password.length < 6) {
            setValidPassword(false);
            return;
        }

        if (password !== repeatPassword) {
            setIncorrectRepeatPassowrd(true);
            setValidRepeatPassword(true);
            return;
        }

        axios.patch(appConfig.completePasswordReset, {
            "password": password,
            "token": token,
            "uidb64": user
        }).then((r: any) => {
            const resultData = r.data;
            setShowSuccess(true);
        }).catch(err => {
            setShowFailure(true);
            console.error(err);
        })
    }

    return (
        <div style={{ marginBottom: '7.9rem' }}>
            {
                showSuccess
                    ?
                    <>
                        <div className={classes.paper} style={{ textAlign: 'center' }}>
                            <Link to="/">
                                <div>
                                    <CheckCircleRoundedIcon style={{ width: '15rem', height: '15rem', color: green[500] }} />

                                </div>
                                <div style={{ color: 'blue', fontSize: 22 }}>
                                    {"Успешна смяна на парола! Може да влезете в профила си!"}
                                </div>
                            </Link>

                        </div>
                    </>
                    :
                    showFailure ?
                        <>
                            <div className={classes.paper} style={{ textAlign: 'center' }}>
                                <Link to="/">
                                    <div>
                                        <CancelRoundedIcon style={{ width: '15rem', height: '15rem', color: 'rgb(255, 0, 0)' }} />
                                    </div>
                                    <div style={{ color: 'blue', fontSize: 22 }}>
                                        {"Неуспешен опит! Моля, опитайте по-късно!"}
                                    </div>
                                </Link>

                            </div>
                        </>
                        :
                        <ThemeProvider theme={theme}>
                            <Container component="main" maxWidth="xs">
                                <CssBaseline />
                                <div className={classes.paper}>
                                    <Avatar className={classes.avatar}>
                                        <LockOutlinedIcon />
                                    </Avatar>
                                    <Typography component="h1" variant="h5">
                                        {"Смяна на парола"}
                                    </Typography>
                                    <form className={classes.form} noValidate>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>

                                                <TextField
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    name="password"
                                                    label="Нова парола"
                                                    type="password"
                                                    id="password"
                                                    autoComplete="current-password"
                                                    error={!validPassword ? true : false}
                                                    helperText={!validPassword ? `${incorrectInput} и трябва да бъде поне 6 символа` : "Паролата трябва да бъде поне 6 символа"}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>

                                                <TextField
                                                    value={repeatPassword}
                                                    onChange={(e) => setRepeatPassword(e.target.value)}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    name="repeatPassword"
                                                    label="Повторете паролата"
                                                    type="password"
                                                    id="repeatPassword"
                                                    autoComplete="repeat-password"
                                                    error={!validRepeatPassword || incorrectRepeatPassowrd ? true : false}
                                                    helperText={!validRepeatPassword ? incorrectInput : incorrectRepeatPassowrd ? incorrectRepeatPassowrdLabel : ''}
                                                />
                                            </Grid>

                                        </Grid>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}
                                            onClick={onNewPasswordSent}
                                        >
                                            {"Изпрати"}
                                        </Button>
                                        <Grid container justify="flex-end">
                                            <Grid item>
                                                <Link to="/">
                                                    {"Вече сте регистриран? Вход"}
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </div>
                            </Container>
                        </ThemeProvider>
            }
        </div>

    );
}

export default SetNewPassword;
