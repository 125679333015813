import * as React from 'react';
import { useEffect, useContext, useState } from 'react';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Courses from './Courses/Courses';
import NewsContainer from './News/NewsContainer';
import TestAnnouncement from './Test/TestAnnouncement';
import DocumentsContainer from './Documents/DocumentsContainer';
import Organizations from './App/Organizations';
import MainMenus from './MainMenus';
import RouteContext from '../context/RouteContext';
import { ITestInfo } from '../context/TestInfoContext';


interface Props {
    testInfo: ITestInfo;
}

const Main = (props: Props) => {
    const [testInfo, setTestInfo] = useState<ITestInfo | null>(props.testInfo);
    const [, setCurrentPath] = useContext(RouteContext);

    useEffect(() => {
        if (window.scrollTo) {
            window.scrollTo(0, 0);
        }
        setCurrentPath('/');
    }, []);

    useEffect(() => {
        setTestInfo(props.testInfo);
    }, [props.testInfo])

    return (
        <div>
            <Container component="div" maxWidth="lg">
                <Organizations />
            </Container>
            <Divider style={{ background: '#E0F7F2', height: 2 }} />
            <Container component="div" maxWidth="lg">
                <div style={{ marginTop: '2rem' }}>
                    <MainMenus />
                </div>
            </Container>
            <Container component="div" maxWidth="lg">
                <div style={{ marginTop: '3rem' }}>
                    <Courses />
                </div>
            </Container>
            <Container component="div" maxWidth="lg">
                <div style={{ marginTop: '3rem' }}>
                    <TestAnnouncement testInfo={testInfo} />
                </div>
            </Container>
            <div style={{ width: '100%', height: '100%', backgroundColor: '#F6FFFD', marginTop: '3rem' }}>
                <div style={{ paddingTop: '3rem' }}>
                    <NewsContainer />
                </div>
            </div>
            {/* <div style={{ width: '100%', height: '100%', marginTop: '3rem' }}>
                <DocumentsContainer />
            </div> */}
        </div>
    )
}

export default Main;
