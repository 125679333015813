import React, { useState, useContext } from 'react';
import { useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Loading from '../Loading/Loading';
import Button from '@material-ui/core/Button';
// import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { NavigateFn, RouteComponentProps } from '@reach/router';
import axios from 'axios';
import { Link } from '@reach/router';
import { theme } from '../../theming/theme';
import AppContext from '../../context/AppContext';
import { appConfig } from '../../appConfig';
import AppUserContext from 'src/context/AppUserContext';
import { setCookie } from '../utils';


const useStyles = makeStyles((theme: any) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
} as any));

type Props = RouteComponentProps & {
    changeCurrentRoute?: (currPath: string) => void;
}

const incorrectInputName = 'Моля, въведете регистрационен номер';
const incorrectInputPassword = 'Моля, въведете парола';

const SignIn = (props: Props) => {
    const classes: any = useStyles();
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [showLoading, setShowLoading] = useState(false);

    const [validName, setValidName] = useState(true);
    const [validPassword, setValidPassword] = useState(true);
    const [wrongCredentials, setWrongCredentials] = useState(false);
    const [unableToConnect, setUnableToConnect] = useState(false);

    const [, setIsOpened] = useContext(AppContext);
    const [, setAppUser] = useContext(AppUserContext);

    useEffect(() => {
        if (window.scrollTo) {
            window.scrollTo(0, 0);
        }
        if (props.changeCurrentRoute) {
            props.changeCurrentRoute('signin');
        }
    }, []);

    const signIn = (e: any) => {
        e.preventDefault()

        if (!userName) {
            setValidName(false);
            return;
        }

        if (!password) {
            setValidPassword(false);
            return;
        }
        setShowLoading(true);

        axios.post(appConfig.userLogin, {
            username: userName,
            password: password
        }).then(r => {
            const signedInUserData = r.data;
            axios.get(appConfig.userData, {
                headers: { 'Authorization': `Token ${signedInUserData['key']}` }
            }).then(userDataResult => {
                setShowLoading(false);
                const userData = userDataResult.data;
                const userId = userData['username'];
                const email = userData['email'];
                const _firstName = userData['first_name'];
                const _lastName = userData['last_name'];
                const _userPk = userData['pk'];

                const loggedInUserData = {
                    firstName: _firstName,
                    lastName: _lastName,
                    userId: userId,
                    email: email,
                    appKey: signedInUserData['key'],
                    user_pk: _userPk
                };

                setAppUser(loggedInUserData);
                setCookie('annual_geo_test', JSON.stringify(loggedInUserData), 7);

                setIsOpened(false);
            }).catch(err => {
                setWrongCredentials(false);
                setUnableToConnect(true);
                setShowLoading(false);
                console.error(err);
            });
        }).catch(err => {
            const errData = err && err.response ? err.response.data : null;
            if (errData && errData['non_field_errors'] && errData['non_field_errors'][0] === 'Unable to log in with provided credentials.') {
                setWrongCredentials(true);
                setUnableToConnect(false);
            } else {
                setWrongCredentials(false);
                setUnableToConnect(true);
            }
            setShowLoading(false);
            console.error(err);
        });
    }

    return (
        <Container component="main" maxWidth="md">
            <ThemeProvider theme={theme}>
                {/* <CssBaseline /> */}
                {
                    showLoading
                        ?
                        <div style={{ height: '30rem', display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                            <Loading style={{ width: 'unset', height: 'unset', textAlign: 'center' }} show={showLoading} />
                        </div>
                        :
                        <div className={classes.paper}>
                            <Typography variant={"h6"} style={{ fontSize: 20, color: '#098363', fontWeight: 700 }}>
                                {"Вход"}
                            </Typography>
                            <br />
                            {
                                wrongCredentials
                                    ?
                                    <div style={{ color: 'red' }}>
                                        {"Невалиден потребител или парола!"}
                                    </div>
                                    :
                                    null
                            }
                            {
                                unableToConnect
                                    ?
                                    <div style={{ color: 'red' }}>
                                        {"Възникна грешка. Моля, опитайте отново!"}
                                    </div>
                                    :
                                    null
                            }
                            <form className={classes.form} noValidate>
                                <div>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                size={"small"}
                                                value={userName}
                                                onChange={(e) => setUserName(e.target.value)}
                                                autoComplete="fname"
                                                name="firstName"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                id="firstName"
                                                label="Регистрационен номер"
                                                error={!validName ? true : false}
                                                helperText={!validName ? incorrectInputName : ""}
                                            />
                                            <Typography variant="caption">
                                                {"Моля, въведете Вашия номер от регистъра на "}<b>{"правоспособните физически лица"}</b> {"по кадастър"}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                size={"small"}
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                name="password"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                type="password"
                                                id="password"
                                                label="Парола"
                                                error={!validPassword ? true : false}
                                                helperText={!validPassword ? incorrectInputPassword : ""}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                                <br />
                                <div style={{ width: '100%', textAlign: 'center' }}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        onClick={(e) => signIn(e)}
                                        style={{ width: "20%", background: "linear-gradient(90deg, #0FC593 0%, #098363 100%)" }}
                                    >
                                        <Typography variant={"h6"} style={{ fontSize: 16, fontWeight: 700 }}>
                                            {"Вход"}
                                        </Typography>
                                    </Button>
                                </div>
                                <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                                    <div style={{ textAlign: 'center', margin: '0px 1rem' }}>
                                        <Link to={"reset"} style={{ textDecoration: 'underline' }} onClick={() => setIsOpened(false)}>{"Забравена парола"}</Link>
                                    </div>
                                    <div style={{ textAlign: 'center', margin: '0px 1rem' }}>
                                        <Link to={"sign-up"} style={{ textDecoration: 'underline' }} onClick={() => setIsOpened(false)}>{"Създаване на потребител"}</Link>
                                    </div>
                                </div>
                            </form>
                        </div>

                }
            </ThemeProvider>

        </Container>
    );
}

export default SignIn;
