import * as React from 'react';
import { useState, useEffect, CSSProperties } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
        },
    }),
);

type Props = {
    show: boolean;
    height?: string;
    width?: string;
    style?: CSSProperties
}

const Loading = (props: Props) => {
    const classes = useStyles();
    const [height, setHeight] = useState(props.height || '100px');
    const [width, setWidth] = useState(props.width || '100px');
    const [show, setShow] = useState(props.show);
    const [style, setStyle] = useState<CSSProperties>(props.style || null);

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    useEffect(() => {
        setHeight(props.height);
        setWidth(props.width);
    }, [props.height, props.width]);

    return (
        <>
            {
                show
                    ?
                    <div style={style ? style : { height: '100%', width: '100%', textAlign: 'center' }}>
                        <CircularProgress style={{ width: 100, height: 100 }} />
                    </div>
                    :
                    null
            }
        </>
    );
}

export default Loading;