import * as React from 'react';
import { useState, useEffect } from 'react';
import { Divider, Typography } from '@material-ui/core';


export interface ITestResult {
    qusetions: Array<any>;
    points: number
}

interface Props {
    result: ITestResult
}

const TestResult = (props: Props) => {
    const [points,] = useState(props.result.points);
    const [qusetions,] = useState(props.result.qusetions || []);

    useEffect(() => {
        window.onbeforeunload = null;
    }, []);

    return (
        <div>
            <Typography variant={"h6"} style={{ fontSize: 30, color: '#098363', fontWeight: 700 }}>
                {"Резултати"}
            </Typography>
            <Divider style={{ background: '#0fc594' }} />

            <div style={{ margin: '1rem', padding: '1rem' }}>
                <Typography variant={"h6"} style={{ fontSize: 18, color: '#098363', fontWeight: 700 }}>
                    {`Общ резултат ${points} точки`}
                </Typography>
            </div>

            <div>
                <Divider style={{ background: '#0fc594' }} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {
                        qusetions.length
                            ?
                            qusetions.map(q => (
                                <>

                                    <div style={{ display: 'flex', flexDirection: 'column', padding: '1rem', margin: '1rem' }}>
                                        {
                                            q.given_answer
                                                ?
                                                <>
                                                    <Typography variant={"h6"} style={{ fontSize: 18, fontWeight: 700 }}>
                                                        {`На въпрос: ${q.question}`}
                                                    </Typography>
                                                    <Typography variant={"body1"} style={{ fontSize: 18, fontWeight: 700, margin: '0.2rem 0px' }}>
                                                        {`Отговорихте: ${q.given_answer}`}
                                                    </Typography>
                                                    <Typography variant={"body1"} style={{ fontSize: 18, fontWeight: 700, margin: '0.2rem 0px' }}>
                                                        {`Верен отговор: ${q.right_answer}`}
                                                    </Typography>
                                                </>
                                                :
                                                <>
                                                    <Typography variant={"h6"} style={{ fontSize: 18, fontWeight: 700, margin: '0.2rem 0px' }}>
                                                        {`На въпрос: ${q.question}`}
                                                    </Typography>
                                                    <Typography variant={"body1"} style={{ fontSize: 18, fontWeight: 700, margin: '0.2rem 0px' }}>
                                                        {`Не е въведен отговор!`}
                                                    </Typography>
                                                </>
                                        }
                                    </div>

                                    <Divider style={{ background: '#0fc594' }} />

                                </>
                            ))
                            :
                            null
                    }
                </div>
            </div>
        </div>
    )
}

export default TestResult;
