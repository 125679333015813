import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Loading from '../Loading/Loading';
import OfficialTestInfo from './OfficialTestInfo';
import { appConfig } from '../../appConfig';
import axios from 'axios';
import TestInfoContext, { ITestInfo } from '../../context/TestInfoContext';


const useStyles = makeStyles((theme: Theme) => createStyles({
    testInfo: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            width: '75%',
        },
        [theme.breakpoints.down('md')]: {
            width: '75%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    }
}));


// interface IOfficialTest {
//     title: string;
//     begin_date: string;
//     end_date: string;
//     content: string;
// }

interface Props {
    testInfo?: ITestInfo
}

const TestAnnouncement = (props: Props) => {
    const classes = useStyles();

    const [showLoading, setShowLoading] = useState(false);
    const [latestOfficialDate, setLatestOfficialDate] = useState<ITestInfo | null>(null);

    // const [, setTestInfo] = useContext(TestInfoContext);

    useEffect(() => {
        // setShowLoading(true);
        // axios.get(appConfig.testAnnouncement).then(r => {
        //     const testAnnouncementData = r.data;
        //     if (testAnnouncementData && testAnnouncementData.length) {
        //         setLatestOfficialDate({
        //             title: testAnnouncementData[0]['title'],
        //             begin_date: testAnnouncementData[0]['begin_date'],
        //             end_date: testAnnouncementData[0]['end_date'],
        //             content: testAnnouncementData[0]['content']
        //         });
        //         setTestInfo({
        //             begin_date: testAnnouncementData[0]['begin_date'],
        //             end_date: testAnnouncementData[0]['end_date'],
        //         });
        //         console.log(testAnnouncementData)
        //         setShowLoading(false);
        //     }
        // }).catch(err => {
        //     console.error(err);
        //     setShowLoading(false);
        // })
    }, []);

    useEffect(() => {
        setLatestOfficialDate({ ...props.testInfo });
    }, [props.testInfo])


    return (
        <>
            {
                showLoading
                    ?
                    <div style={{ height: '30rem', display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                        <Loading style={{ width: 'unset', height: 'unset', textAlign: 'center' }} show={showLoading} />
                    </div>
                    :
                    <>
                        {
                            latestOfficialDate
                                ?
                                <>
                                    <div style={{ textAlign: 'center', marginBottom: '2rem', marginTop: '2rem' }}>
                                        <Typography component="div" variant="h6" style={{ fontSize: 24, color: '#098363' }}>
                                            {"Провеждане на официален тест за придобиване на удостоверение"}
                                        </Typography>
                                    </div>
                                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <div className={classes.testInfo}>

                                            <OfficialTestInfo
                                                title={latestOfficialDate.title}
                                                beginDate={latestOfficialDate.begin_date}
                                                endDate={latestOfficialDate.end_date}
                                                description={latestOfficialDate.content}
                                            />

                                        </div>
                                    </div>
                                </>
                                :
                                null
                        }
                    </>
            }

        </>
    )
}

export default TestAnnouncement;
