import { IUserData } from "src/context/AppUserContext";
import { CSSProperties } from 'react';
import { ITestInfo } from '../context/TestInfoContext';

export const cities: Array<string> = [
    "Благоевград",
    "Бургас",
    "Варна",
    "Велико Търново",
    "Видин",
    "Враца ",
    "Габрово",
    "Добрич",
    "Кърджали",
    "Кюстендил",
    "Ловеч",
    "Монтана",
    "Пазарджик",
    "Плевен",
    "Перник",
    "Пловдив",
    "Разград",
    "Русе",
    "Силистра",
    "Сливен",
    "Смолян",
    "София",
    "Стара Загора",
    "Търговище",
    "Хасково",
    "Шумен",
    "Ямбол"
]

export const setCookie = (name: string, value: string, days: number) => {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export const getCookie = (name: string) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export const eraseCookie = (name: string) => {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export const getDataFromCookie = () => {
    const data = getCookie('annual_geo_test');
    if (data) {
        const parsedData = JSON.parse(data);
        return parsedData as IUserData;
    }
    return {} as IUserData
}

export const arrowStyle: CSSProperties = {
    margin: 0,
    position: 'absolute',
    left: '50%',
    msTransform: 'translate(-50%, 30%)',
    transform: 'translate(-50%, 30%)',
    color: '#ffffff'
}

export const rotatedArrowStyle: CSSProperties = {
    ...arrowStyle,
    msTransform: 'translate(-50%, 30%) rotate(-90deg)',
    transform: 'translate(-50%, 30%) rotate(-90deg)',
}

export const showAllTextCss: CSSProperties = {
    overflow: 'auto',
    width: '100%',
}

export const getQueryVariable = (variable: string) => {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
}



export const isTestAllowed = (currentTestInfo: ITestInfo) => {
    let isCurrentlyAllowed = false;
    if (currentTestInfo.begin_date && currentTestInfo.end_date) {
        const today = new Date().getTime();
        const beginDate = getTargetDate(currentTestInfo.begin_date);
        const beginDateMilliSeconds = new Date(beginDate.year, beginDate.month - 1, beginDate.day).getTime();

        const endDate = getTargetDate(currentTestInfo.end_date);
        const endDateMilliSeconds = new Date(endDate.year, endDate.month - 1, endDate.day).getTime();

        if (beginDateMilliSeconds < today && endDateMilliSeconds > today) {
            isCurrentlyAllowed = true;
        }

    }

    return isCurrentlyAllowed;
}

const getTargetDate = (targetDateValue: string) => {
    const splittedDate = targetDateValue.split('-');

    const year = +splittedDate[0];
    const month = +splittedDate[1];
    const day = +splittedDate[2];

    return { year, month, day };
}