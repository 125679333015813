import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Link } from '@reach/router'
import { IUserData } from 'src/context/AppUserContext';
import { ITestInfo } from 'src/context/TestInfoContext';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Card, CardContent, Typography } from '@material-ui/core';
import ExamIcon from '../../assets/exam.png';
import MainExamIcon from '../../assets/proposal.svg';
import { isTestAllowed } from '../utils';
import InfoButton from '../../assets/information-button.svg';


const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        minWidth: 100,
        borderRadius: 15,
        filter: 'drop-shadow(0 0 7.5px rgba(0,0,0,0.15))',
        backgroundColor: '#ffffff',
        borderColor: '#ffffff',
        margin: '1rem',
        height: '12rem',
        [theme.breakpoints.up('md')]: {
            maxWidth: '45%',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '60%',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '75%',
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '90%',
        },
    },
    rootText: {
        minWidth: 100,
        borderRadius: 15,
        filter: 'drop-shadow(0 0 7.5px rgba(0,0,0,0.15))',
        backgroundColor: '#ffffff',
        borderColor: '#ffffff',
        [theme.breakpoints.up('sm')]: {
            margin: '1rem',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '1rem 0px'
        },
    },
    certificateInfo: {
        textAlign: 'justify',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
            padding: '1rem',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '1rem 0px'
        }
    },
    infoText: {
        fontSize: 16,
        width: '85%',
        [theme.breakpoints.up('sm')]: {
            padding: '1rem',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '1rem 0px'
        },
    }
}));


type Props = RouteComponentProps & {
    changeCurrentRoute: (currPath: string) => void;
    userData: IUserData;
    testInfo?: ITestInfo;
}


const TestPage = (props: Props) => {
    const classes = useStyles();

    const [userData, setUserData] = useState(props.userData || {});
    const [testInfo, setTestInfo] = useState<ITestInfo>(props.testInfo);


    useEffect(() => {
        if (window.scrollTo) {
            window.scrollTo(0, 0);
        }
        props.changeCurrentRoute('test-page');
    }, []);

    useEffect(() => {
        setUserData(props.userData);
    }, [props.userData]);

    useEffect(() => {
        setTestInfo(props.testInfo);
    }, [props.testInfo]);

    const openTest = (testType: string) => {
        if (Object.keys(userData).length) {
            if (testType === 'sample' || isTestAllowed(testInfo)) {
                window.open(`${window.location.origin}${window.location.pathname}?testType=${testType}#/test/`, '_blank');
            } else {
                alert(`Тестът ще бъде достъпен от дата ${testInfo.begin_date} 00:00 часа до дата ${testInfo.end_date} 00:00 часа`);
            }
        } else {
            alert("За да достъпите тест за проверка на знания, първо трябва да влезете в профила си!");
        }
    }

    return (
        <div style={{ minHeight: '75vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ maxWidth: '50%' }}>
                <Card className={classes.rootText} variant="outlined">
                    <CardContent style={{ padding: 0 }}>
                        <div className={classes.certificateInfo}>
                            <img src={InfoButton} style={{ width: '8rem', height: '8rem' }} />
                            <Typography variant={"body1"} className={classes.infoText}>
                                {"Във всеки момент можете да проверите знанията си като изберете „пробен тест“. В предварително обявен период от време можете да решите „официален тест“, резултатите от който се признават за издаване на удостоверение. Независимо от избора на вариантите, тестът съдържа 10 въпроса с 4 отговора, единият от които е верен. Времето за решаване на теста е 10 минути."}
                            </Typography>
                        </div>
                    </CardContent>
                </Card>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '45vh' }}>
                <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                        <Card className={classes.root} variant="outlined">
                            <CardContent style={{ padding: 0 }}>
                                <div style={{ textDecoration: 'none', color: '#808080', cursor: 'pointer' }} onClick={() => { openTest('sample') }}>
                                    <div style={{ display: 'flex', height: '100%', padding: '1rem' }}>
                                        <div style={{ margin: '1rem' }}>
                                            <img style={{ width: '6rem', height: '6rem' }} src={ExamIcon} />
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', margin: '0.5rem', flexDirection: 'column', justifyContent: 'center' }}>
                                            <Typography variant={"h6"} style={{ marginBottom: '0.5rem', fontSize: 18, color: '#098363' }}>{"Пробен тест"}</Typography>
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                        <Card className={classes.root} variant="outlined">
                            <CardContent style={{ padding: 0 }}>
                                <div style={{ textDecoration: 'none', color: '#808080', cursor: 'pointer' }} onClick={() => { openTest('official') }}>
                                    <div style={{ display: 'flex', height: '100%', padding: '1rem' }}>
                                        <div style={{ margin: '1rem' }}>
                                            <img style={{ width: '6rem', height: '6rem' }} src={MainExamIcon} />
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', margin: '0.5rem', flexDirection: 'column', justifyContent: 'center' }}>
                                            <Typography variant={"h6"} style={{ marginBottom: '0.5rem', fontSize: 18, color: '#098363' }}>{"Официален тест"}</Typography>
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TestPage;