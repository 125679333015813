import * as React from 'react';
import { useEffect, useContext, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import Main from './Main';
import RouteContext from '../context/RouteContext';
import { ITestInfo } from '../context/TestInfoContext';

interface Props extends RouteComponentProps {
    changeCurrentRoute: (currPath: string) => void;
    testInfo?: ITestInfo
}

const HomePage = (props: Props) => {
    const [, setCurrentPath] = useContext(RouteContext);

    const [testInfo, setTestInfo] = useState(props.testInfo);

    useEffect(() => {
        if (window.scrollTo) {
            window.scrollTo(0, 0);
        }
        props.changeCurrentRoute('/');
    }, []);

    useEffect(() => {
        setTestInfo(props.testInfo);
    }, [props.testInfo]);

    return (
        <Main testInfo={testInfo} />
    )
}

export default HomePage;
