import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import axios from 'axios';
import AccordionSummary from '../Accordion/AccordionSummary';
import Accordion from '../Accordion/Accordion';
import AccordionDetails from '../Accordion/AccordionDetails';
import News from './News';
import AppPagination from '../App/AppPagination';
import UnderConstructionIcon from '../../assets/under-construction.svg';
import { appConfig } from '../../appConfig';
import Loading from '../Loading/Loading';
import { INewsItem } from './INewsInterfaces';
import { IUserData } from '../../context/AppUserContext';
// import QuestionsContainer from '../Forum/QuestionsContainer';


interface Props extends RouteComponentProps {
    changeCurrentRoute: (currPath: string) => void;
    userData: IUserData;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(1.5)',
        color: '#0fc594'
    },
    desktopNews: {
        [theme.breakpoints.up('sm')]: {
            minHeight: '100%',
            marginLeft: 'auto'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    mobileNews: {
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
    }
}));

const NewsFeed = (props: Props) => {
    const classes = useStyles();

    const bull = <span className={classes.bullet}>•</span>;
    // const [expanded, setExpanded] = React.useState<string | false>('panel1');
    const [selectedContent, setSelectedContent] = useState<'news' | 'forum'>('news');
    const [newsItems, setNewsItems] = useState<Array<INewsItem>>([]);
    const [pages, setPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        if (window.scrollTo) {
            window.scrollTo(0, 0);
        }
        props.changeCurrentRoute(props.path);
        getNews();
    }, []);

    const getNews = () => {
        getNewsRequest(5, 0)
    }

    const getNewsRequest = (count: number, offset: number) => {
        setShowLoading(true);
        axios.get(appConfig.allNews, {
            params: {
                count,
                offset
            }
        }).then(r => {
            const resultNewsData = r.data;
            const newsDataFormatted = resultNewsData.reduce((acc: Array<INewsItem>, curr: INewsItem) => {
                const currentDate = curr['create_date'];
                const currentDateSplitted = currentDate.split('-');
                curr['create_date'] = `${currentDateSplitted[2]}/${currentDateSplitted[1]}/${currentDateSplitted[0]}`;
                acc.push(curr);
                return acc;
            }, [] as Array<INewsItem>);
            const newsCount = newsDataFormatted.length;
            let calculatedPageCount = 0;
            if (newsCount) {
                if (newsCount < 5) {
                    calculatedPageCount = 1;
                } else {
                    calculatedPageCount = Math.floor(newsCount / 5);
                }
            }
            setCurrentPage(1);
            setShowLoading(false);
            setNewsItems(newsDataFormatted);
            setPages(calculatedPageCount);
        }).catch(err => {
            setShowLoading(false);
            console.error(err);
        });
    }

    const onPageChange = (pageValue: number) => {
        if (pageValue !== currentPage) {
            const count = 5;
            const offset = (pageValue * 5) - 5;
            getNewsRequest(count, offset);
        }
    }

    const pageContent = <>
        {
            selectedContent === 'news'
                ?
                showLoading
                    ?
                    <div style={{ height: '30rem', display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                        <Loading style={{ width: 'unset', height: 'unset', textAlign: 'center' }} show={showLoading} />
                    </div>
                    :
                    <div style={{ marginTop: '4rem', paddingLeft: '1rem', marginBottom: '4rem' }}>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ width: '100%', }}>
                                {
                                    newsItems.length
                                        ?
                                        newsItems.map((newItem, i) => (
                                            <News key={`news_${i}`} title={newItem.title} date={newItem.create_date} description={newItem.content} />
                                        ))
                                        :
                                        "Не са намерени новини!"
                                }
                            </div>
                        </div>
                        {
                            pages
                                ?
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <AppPagination pageCount={pages} onChange={onPageChange} />
                                </div>
                                :
                                null

                        }
                    </div>
                :
                // selectedContent === 'forum'
                //     ?
                //     <div style={{ marginTop: '4rem', marginBottom: '4rem' }}>
                //         {/* <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                //             <img src={UnderConstructionIcon} style={{ width: '12rem', height: '12rem' }} />
                //             <Typography>
                //                 {"В процес на разработка"}
                //             </Typography>
                //         </div> */}
                //         <QuestionsContainer userData={props.userData} />
                //     </div>
                //     :
                null

        }
    </>

    return (
        <Container component="div" maxWidth="lg">
            <Grid container spacing={3} className={classes.desktopNews}>
                <Grid item xs={12} sm={3}>
                    <div style={{ width: '100%', display: 'flex' }}>
                        <div style={{ marginTop: '4rem', paddingRight: '2rem' }}>
                            <Accordion square expanded={true}>
                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" style={{ minWidth: '12rem' }}>
                                    <ArrowDropDownRoundedIcon style={{ color: '#ffffff' }} />
                                    <Typography variant={"h6"} style={{ color: '#ffffff', fontSize: 14 }}>
                                        {"Новини"}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {/* <div style={{ display: 'flex', marginTop: '0.5rem', cursor: 'pointer' }} onClick={() => setSelectedContent('news')}>
                                        <div style={{ display: 'inline-block', marginRight: '0.5rem' }}>
                                            {bull}
                                        </div>
                                        <div style={{ display: 'inline-block' }}>
                                            <Typography variant={selectedContent === 'news' ? 'h6' : 'body1'} style={{ fontSize: 14 }}>
                                                {"Новини"}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: '0.5rem', cursor: 'pointer' }} onClick={() => setSelectedContent('forum')}>
                                        <div style={{ display: 'inline-block', marginRight: '0.5rem' }}>
                                            {bull}
                                        </div>
                                        <div style={{ display: 'inline-block' }}>
                                            <Typography variant={selectedContent === 'forum' ? 'h6' : 'body1'} style={{ fontSize: 14 }}>
                                                {"Форум"}
                                            </Typography>
                                        </div>
                                    </div> */}
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <Divider style={{ height: '80rem', marginTop: '2rem', marginBottom: '2rem' }} orientation={"vertical"} />
                    </div>
                </Grid>
                <Grid item xs={12} sm={9}>
                    {pageContent}
                </Grid>
            </Grid>
            <div className={classes.mobileNews}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', padding: '1rem', margin: '1rem' }}>
                    <div style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => setSelectedContent('news')}>
                        <Typography variant={selectedContent === 'news' ? 'h6' : 'body1'} style={{ fontSize: 16 }}>
                            {"Новини"}
                        </Typography>
                    </div>
                    <div style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => setSelectedContent('forum')}>
                        <Typography variant={selectedContent === 'forum' ? 'h6' : 'body1'} style={{ fontSize: 16 }}>
                            {"Форум"}
                        </Typography>
                    </div>
                </div>
                <Divider style={{ background: '#E0F7F2' }} />
                {pageContent}
            </div>
        </Container>
    );
}

export default NewsFeed;
