import * as React from 'react';
import { useEffect, useContext, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import RouteContext from '../../context/RouteContext';
import Typography from '@material-ui/core/Typography';
import { createStyles, Theme, makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../theming/theme';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CountDownTimer from './CountDownTimer';
import time_icon from '../../assets/time_icon.png';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import Test from './Test';
import TestInfo from './TestInfo';
import axios from 'axios';
import { appConfig } from '../../appConfig';
import Loading from '../Loading/Loading';
import TestResult, { ITestResult } from './TestResult';
import { IUserData } from 'src/context/AppUserContext';
import { ITestInfo } from 'src/context/TestInfoContext';
import { getQueryVariable, isTestAllowed } from '../utils';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // flexGrow: 1,
            // display: 'flex',
            // justifyContent: 'center'
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        // title: {
        //     flexGrow: 1,
        //     textAlign: 'center',
        //     [theme.breakpoints.up('sm')]: {
        //         display: 'block',
        //         textAlign: 'center'

        //     },
        // },
        card: {
            borderRadius: 15,
            margin: '1rem 0px',
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
    }),
);



type Props = RouteComponentProps & {
    userData: IUserData;
    testInfo?: ITestInfo;
}

let currentAnswer = 0;
let testType = 'unknown';

const TestContainer = (props: Props) => {
    const classes = useStyles();
    const [testStarted, setTestStarted] = useState(false);
    const [questionNumber, setQuestionNumber] = useState(0);
    const [question, setQuestion] = useState('');
    const [answers, setAnswers] = useState([]);
    const [currnetTestId, setCurrnetTestId] = useState(0);
    const [showLoading, setShowLoading] = useState(false);
    const [showTestResult, setShowTestResult] = useState(false);
    const [testResult, setTestResult] = useState<ITestResult>({} as ITestResult);

    const [testInfo, setTestInfo] = useState(props.testInfo);

    const [, setCurrentPath] = useContext(RouteContext);

    useEffect(() => {
        if (window.scrollTo) {
            window.scrollTo(0, 0);
        }
        const _testType = getQueryVariable('testType');
        if (_testType === 'official' || _testType === 'sample') {
            testType = _testType;
        }
        setCurrentPath('test');
        window.onbeforeunload = function () {
            return "Confirm refresh";
        };
        if (!Object.keys(props.userData).length) {
            window.location.href = `${window.location.origin}${window.location.pathname}#/`;
        }
    }, []);

    useEffect(() => {
        setTestInfo(props.testInfo);
    }, [props.testInfo]);

    const onBegin = () => {


        if (testType === 'sample' || isTestAllowed(testInfo)) {

            setShowLoading(true);
            const startTime = new Date().getTime();
            axios.post(appConfig.startTest, {
                USER: props.userData.user_pk,
                QTYPE: '1',
                START: getStartTime(startTime),
                END: getEndTime(startTime),
                RESULT: '0',
                STATUS: '0',
                TESTTYPE: testType,
            }, {
                headers: { 'Authorization': `Token ${props.userData.appKey}` }
            }).then(r => {
                const questionnarieData = r.data;
                const testId = questionnarieData.testId;

                axios.post(appConfig.answerAndGetQuestion, {
                    questionaire: testId,
                    next_q_number: 1,
                    questtype: 1,
                    answer: 0,
                    user: props.userData.user_pk
                }, {
                    headers: { 'Authorization': `Token ${props.userData.appKey}` }
                }).then(questionRes => {
                    const questionResData = questionRes.data;
                    const _question = questionResData['question'];
                    const _answers = questionResData['answers'];

                    setQuestion(_question);
                    setAnswers(_answers);
                    setQuestionNumber(1);
                    setCurrnetTestId(testId);
                    setShowLoading(false);

                }).catch(err => {
                    setShowLoading(false);
                    console.error(err);
                });


            }).catch(err => {
                console.error(err);
            })
            setTestStarted(true);


        } else {
            alert(`Тестът ще бъде достъпен от дата ${testInfo.begin_date} 00:00 часа до дата ${testInfo.end_date} 00:00 часа`);
        }
    }

    const getStartTime = (time: number) => {
        return timeFormatter(time);
    }

    const getEndTime = (time: number) => {
        return timeFormatter(time + 600500);
    }

    const timeFormatter = (time: number) => {
        const timeObject = new Date(time);

        return `${timeObject.getFullYear()}-${timeObject.getMonth() + 1}-${timeObject.getDate()}T${timeObject.getHours()}:${timeObject.getMinutes()}`;
    }

    const onTestEnd = () => {
        nextQuestion(0, true);
    }

    const nextQuestion = (selectedAnswer: number, endTest?: boolean) => {
        setShowLoading(true);
        const currentQuestion = endTest ? 11 : questionNumber + 1;
        axios.post(appConfig.answerAndGetQuestion, {
            questionaire: currnetTestId,
            next_q_number: currentQuestion,
            questtype: 1,
            answer: selectedAnswer,
            user: props.userData.user_pk
        }, {
            headers: { 'Authorization': `Token ${props.userData.appKey}` }
        }).then(questionRes => {
            const questionResData = questionRes.data;
            if (currentQuestion > 10) {
                setTestResult(questionResData);
                setShowTestResult(true);
                setTestStarted(false);
            } else {
                const _question = questionResData['question'];
                const _answers = questionResData['answers'];

                currentAnswer = 0;
                setQuestion(_question);
                setAnswers(_answers);
                setQuestionNumber(currentQuestion);
                setShowLoading(false);
            }

        }).catch(err => {
            console.error(err);
            setShowLoading(false);
        });
    }

    const onAnswerChange = (answer: number) => {
        currentAnswer = answer;
    }

    return (

        <div style={{ height: '100vh', overflow: 'auto', background: '#F6FFFD' }}>
            <div className={classes.root}>
                <ThemeProvider theme={theme}>
                    <div style={{
                        minHeight: '15rem', background: "linear-gradient(90deg, #098363 20%, #0FC593 100%)", width: '100%'
                    }}>
                    </div>

                </ThemeProvider>
            </div>
            <div style={{ position: 'relative' }}>
                <div style={{
                    marginTop: '-5.5rem',
                    position: 'absolute',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <AccountCircleRoundedIcon style={{ color: '#ffffff', width: '2rem', height: '2rem' }} />
                            <Typography variant={"body1"} style={{ fontSize: 18, color: '#ffffff' }}>
                                {props.userData.firstName}
                            </Typography>
                        </div>
                        <Typography variant={"body1"} style={{ fontSize: 24, color: '#ffffff' }}>
                            {
                                testType === 'official'
                                    ?
                                    "Официален тест"
                                    :
                                    "Пробен тест"
                            }
                        </Typography>
                        {
                            testStarted
                                ?
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={time_icon} width={24} height={23} />
                                    <div style={{ width: '6rem', paddingLeft: 10 }}>
                                        <CountDownTimer onTestEnd={onTestEnd} />
                                    </div>
                                </div>
                                :
                                null
                        }
                    </div>
                </div>
                <Container component="div" maxWidth="lg" style={{ marginTop: '-8rem' }}>
                    <Card className={classes.card}>
                        <CardContent>
                            {
                                showTestResult
                                    ?
                                    <TestResult result={testResult} />
                                    :
                                    showLoading
                                        ?
                                        <div style={{ height: '30rem', display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                            <Loading style={{ width: 'unset', height: 'unset', textAlign: 'center' }} show={showLoading} />
                                        </div>
                                        :
                                        testStarted
                                            ?
                                            <Test
                                                questionNumber={questionNumber}
                                                question={question}
                                                answers={answers}
                                                nextQuestion={nextQuestion}
                                                onAnswerChange={onAnswerChange}
                                            />
                                            :
                                            <TestInfo onBegin={onBegin} />

                            }
                        </CardContent>
                    </Card>
                </Container>

            </div>
        </div>
    )
}

export default TestContainer;
