import React from 'react';
import { useState, useEffect, FunctionComponent } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            '& > *': {
                marginTop: theme.spacing(2),
            },
        },
    }),
);

interface Props {
    pageCount: number;
    onChange: (page: number) => void;
}

const AppPagination = (props: Props) => {
    const classes = useStyles();
    const [pageCount, setPageCount] = useState(props.pageCount);

    useEffect(() => {
        setPageCount(props.pageCount);
    }, [props.pageCount]);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        props.onChange(value);
    }

    return (
        <div className={classes.root}>
            <Pagination siblingCount={0} count={pageCount} color="primary" onChange={handleChange} />
        </div>
    );
}

export default AppPagination;
