import { Typography } from '@material-ui/core';
import * as React from 'react';
import { useState, useEffect } from 'react';

interface Props {
    onTestEnd: () => void;
}

const CountDownTimer = (props: Props) => {
    const calculateTimeLeft = () => {
        const now = new Date().getTime();
        // const endTime = now + 301000;
        const distance = endTime - now;
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        return `${(minutes < 10 ? ('0' + minutes) : minutes)}:${seconds < 10 ? ("0" + seconds) : seconds}`;
    }

    const [endTime,] = useState(new Date().getTime() + 600500);
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());


    useEffect(() => {
        const now = new Date().getTime();
        if ((endTime - now) < 1000) {
            setTimeLeft('00:00');
            props.onTestEnd();
            return () => { };
        }

        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
        return () => clearTimeout(timer);
    });


    return (
        <Typography variant={"h6"} style={{ fontSize: 24, color: '#ffffff' }}>
            {timeLeft}
        </Typography>
    )
}

export default CountDownTimer;
