export const appConfig: any = {};

export const getAppConfig = (configUrl: string) => {
    return new Promise ((resolve) => {
        fetch(configUrl).then(r => {
            r.json().then(json => {
                for (const key in json) {
                    if (json.hasOwnProperty(key)) {
                        appConfig[key] = json[key];
                    }
                }
                resolve(appConfig);
            })
        });
    })
}
