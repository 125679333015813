import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps, NavigateFn } from '@reach/router';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CourseRegister from './CourseRegister';
import Typography from '@material-ui/core/Typography';
import InfoButton from '../../assets/information-button.svg';
import Courses from './Courses';
import AccordionSummary from '../Accordion/AccordionSummary';
import Accordion from '../Accordion/Accordion';
import AccordionDetails from '../Accordion/AccordionDetails';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { IUserData } from '../../context/AppUserContext';


const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        minWidth: 100,
        borderRadius: 15,
        filter: 'drop-shadow(0 0 7.5px rgba(0,0,0,0.15))',
        backgroundColor: '#ffffff',
        borderColor: '#ffffff',
        margin: '1rem',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(1.5)',
        color: '#0fc594'
    },
    desktopCourses: {
        [theme.breakpoints.up('sm')]: {
            minHeight: '100%',
            marginLeft: 'auto'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    mobileCourses: {
        marginBottom: '2rem',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
    }
}));


interface Props extends RouteComponentProps {
    changeCurrentRoute: (currPath: string) => void;
    navigate: NavigateFn;
    userData: IUserData;
}

const CourseContainer = (props: Props) => {
    const classes = useStyles();
    const bull = <span className={classes.bullet}>•</span>;
    const [selectedContent, setSelectedContent] = useState<'coursesInfo' | 'coursesRegistration'>('coursesInfo');
    const [userData, setUserData] = useState(props.userData);

    useEffect(() => {
        if (window.scrollTo) {
            window.scrollTo(0, 0);
        }
        props.changeCurrentRoute('courses');
    }, []);

    useEffect(() => {
        setUserData(props.userData);
    }, [props.userData]);

    const onUserSignIn = () => {
        if (Object.keys(userData).length) {
            alert('Вече сте влезли в системата!');
        } else {
            props.navigate("/sign-up/");
        }
    }

    const onCertificateRequest = () => {
        // if (Object.keys(userData).length) {
        //     alert('Вече сте влезли в системата!');
        // } else {
        props.navigate("/certificate/");
        // }
    }

    const coursesContent = <>
        <div>
            <div style={{ marginTop: '4rem', paddingLeft: '1rem' }}>
                {
                    selectedContent === 'coursesInfo'
                        ?
                        <>
                            <Courses count={100} />

                            <Card className={classes.root} variant="outlined">
                                <CardContent style={{ padding: 0 }}>
                                    <div style={{ textAlign: 'justify', padding: '1rem', display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>
                                        <img src={InfoButton} style={{ width: '8rem', height: '8rem' }} />
                                        <Typography variant={"body1"} style={{ fontSize: 16, padding: '1rem', width: '85%' }}>
                                            {"Тук можете да получите информация за предстоящи и преминали присъствени курсове за обучение. Всички курсове се провеждат по одобрената от АГКК програма за повишаване квалификацията на правоспособни лица. За да присъствате на предстоящия курс, трябва да попълните формата за регистрация. Формата може да се попълва само от регистрирани в платформата потребители."}
                                            {", ако не сте регистрирани изберете регистрация от началната страница или от "}
                                            <span style={{
                                                fontSize: 18,
                                                color: 'rgb(68, 114, 196)',
                                                textDecoration: 'underline',
                                                cursor: 'pointer'
                                            }} onClick={onUserSignIn}>{"тук"}</span>{" и се регистрирайте."}
                                        </Typography>

                                        <div>
                                            <div style={{ marginTop: '2rem' }}>
                                                <Typography variant={"h6"} style={{ fontWeight: 700, fontSize: 16, color: '#098363', textTransform: 'uppercase' }}>
                                                    {"Форми на обучение, които предлагаме"}
                                                </Typography>
                                            </div>
                                            <div style={{ marginTop: '0.2rem' }}>
                                                <Typography variant={"body1"} style={{ fontSize: 14, }}>
                                                    {"При регистрацията за курс имате възможност да заявите следните форми на обучение:"}
                                                </Typography>
                                            </div>
                                            <div style={{ marginTop: '2rem' }}>
                                                <Typography variant={"body1"} style={{ fontSize: 14, marginTop: '0.2rem' }}>
                                                    {"1)"}<span style={{ whiteSpace: 'break-spaces' }}>    </span>
                                                    {"Самообучение. Платформата предоставя и осигурява свободен достъп до всички учебни материали, които правоспособните лица да използват за самоподготовка. В раздел „проверка на знанията“ с пробен тест можете да проверите нивото на подготовката си. Следете информацията, публикувана на страницата за да разберете кога можете да попълните официален тест. Правоспособните лица, заявили форма на обучение „самообучение“ и успешно покрили изискванията  на официалния тест могат да заявят удостоверение "}
                                                    <span style={{
                                                        fontSize: 14,
                                                        color: 'rgb(68, 114, 196)',
                                                        textDecoration: 'underline',
                                                        cursor: 'pointer'
                                                    }} onClick={onCertificateRequest}>{"тук;"}</span>
                                                </Typography>
                                                <Typography variant={"body1"} style={{ fontSize: 14, marginTop: '0.2rem' }}>
                                                    {"2)"}<span style={{ whiteSpace: 'break-spaces' }}>    </span>
                                                    {"Присъствен курс. Информация за предстоящи присъствени курсове можете да получите на тази страница. Регистрирайте за присъствен курс на обучение. След провеждане на присъствен курс, в страницата ще бъде обявен период от време, в което можете да попълните официален тест и да заявите удостоверение "}
                                                    <span style={{
                                                        fontSize: 14,
                                                        color: 'rgb(68, 114, 196)',
                                                        textDecoration: 'underline',
                                                        cursor: 'pointer'
                                                    }} onClick={onUserSignIn}>{"тук;"}</span>
                                                </Typography>
                                                <Typography variant={"body1"} style={{ fontSize: 14, marginTop: '0.2rem' }}>
                                                    {"3)"}<span style={{ whiteSpace: 'break-spaces' }}>    </span>{"Онлайн курс, който съвпада по време с обявения присъствен курс. Регистрираните за онлайн курс правоспособни лица получават възможност за онлайн присъствие на курса през платформата  Zoom. Както и в останалите случаи могат да попълнят официален тест в обявен период от време и да попълнят формата за издаване на удостоверение;"}
                                                </Typography>
                                            </div>
                                        </div>



                                        {/* <div>
                                            <div style={{ marginTop: '2rem' }}>
                                                <Typography variant={"h6"} style={{ fontWeight: 700, fontSize: 16, color: '#098363', textTransform: 'uppercase' }}>
                                                    {"Форми на обучение:"}
                                                </Typography>
                                            </div>
                                            <div style={{ marginTop: '2rem' }}>
                                                <Typography variant={"h6"} style={{ fontWeight: 700, fontSize: 16, }}>
                                                    {"1. Онлайн самообучение"}
                                                </Typography>
                                                <Typography variant={"body1"} style={{ fontWeight: 600, fontSize: 14, }}>
                                                    {"Онлайн обучението предвижда достъп през Интернет до платформа, която осигурява:"}
                                                </Typography>
                                            </div>
                                            <div style={{ marginTop: '2rem' }}>
                                                <Typography variant={"body1"} style={{ fontWeight: 600, fontSize: 14, }}>
                                                    {"1)"}<span style={{ whiteSpace: 'break-spaces' }}>    </span>{"Регистрация на правоспособните лица в специално разработена за целите на обучението уеб платформа;"}
                                                </Typography>
                                                <Typography variant={"body1"} style={{ fontWeight: 600, fontSize: 14, }}>
                                                    {"2)"}<span style={{ whiteSpace: 'break-spaces' }}>    </span>{"Предоставяне и осигуряване на свободен достъп през платформата на всички учебни материали, които правоспособните лица да използват за самоподготовка;"}
                                                </Typography>
                                                <Typography variant={"body1"} style={{ fontWeight: 600, fontSize: 14, }}>
                                                    {"3)"}<span style={{ whiteSpace: 'break-spaces' }}>    </span>{"Онлайн форум, в който правоспособните лица могат да задават въпроси към конкретен преподавател, да поставят казуси и да ги предлагат за обсъждане, както и други форми на комуникация между преподавателите и/или останалите правоспособни лица;"}
                                                </Typography>
                                                <Typography variant={"body1"} style={{ fontWeight: 600, fontSize: 14, }}>
                                                    {"4)"}<span style={{ whiteSpace: 'break-spaces' }}>    </span>{"Онлайн тестове за проверка на наученото. Във всеки момент, участниците в обучението могат да проверят своите знания като отговорят на случайно подбрани от системата въпроси. Резултатите от тестовете се запазват в системата. Въз основа на тях може да се извършва статистика. Тези тестове са междинни и нямат отношение към издаването на удостоверение за преминато обучение;"}
                                                </Typography>
                                                <Typography variant={"body1"} style={{ fontWeight: 600, fontSize: 14, }}>
                                                    {"5)"}<span style={{ whiteSpace: 'break-spaces' }}>    </span>{"Завършване на обучението. Правоспособните лица завършват курса на обучение с издаване на удостоверение, което показва успешно преминаване на курса. Удостоверението се поличава след успешно преминат тест. Тестът е затворен, като всеки въпрос съдържа 4 отговора. Тестовете за получаване на удостоверение се насрочват преиодично, няколко дни преди самото провеждане. В обявения ден и час правоспособните лица, регистрирани в платформата могат да се включат и да отговорят на въпросите от теста."}
                                                </Typography>
                                            </div>
                                        </div> */}

                                    </div>
                                </CardContent>
                            </Card>
                        </>
                        :
                        <div className={classes.root}>
                            <CourseRegister userData={userData} />
                        </div>
                }
            </div>
        </div>
    </>

    return (
        <div>
            <Container component="main" maxWidth="lg">
                <Grid container spacing={3} className={classes.desktopCourses}>
                    <Grid item xs={12} sm={4}>
                        <div style={{ width: '100%', display: 'flex' }}>
                            <div style={{ marginTop: '4rem', paddingRight: '3rem' }}>
                                <Accordion square expanded={true} onChange={() => { }} style={{ background: 'transparent' }}>
                                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" style={{ background: '#0fc594', minWidth: '17rem' }}>
                                        <Typography variant={"h6"} style={{ color: '#ffffff', fontSize: 14 }}>
                                            {"Курсове за обучение"}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div style={{ display: 'flex', marginTop: '0.3rem', cursor: 'pointer' }} onClick={() => setSelectedContent('coursesInfo')}>
                                            <div style={{ display: 'inline-block', marginRight: '0.3rem' }}>
                                                {bull}
                                            </div>
                                            <div style={{ display: 'inline-block' }}>
                                                <Typography variant={selectedContent === 'coursesInfo' ? 'h6' : "body1"} style={{ fontSize: 14 }}>
                                                    {"Информация за курсове"}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', marginTop: '0.3rem', cursor: 'pointer' }} onClick={() => setSelectedContent('coursesRegistration')}>
                                            <div style={{ display: 'inline-block', marginRight: '0.3rem' }}>
                                                {bull}
                                            </div>
                                            <div style={{ display: 'inline-block' }}>
                                                <Typography variant={selectedContent === 'coursesRegistration' ? 'h6' : "body1"} style={{ fontSize: 14 }}>
                                                    {"Регистрация"}
                                                </Typography>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <Divider style={{ height: '75rem', marginTop: '2rem', marginBottom: '2rem' }} orientation={"vertical"} />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        {coursesContent}
                    </Grid>

                </Grid>
                <div className={classes.mobileCourses}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', padding: '1rem', margin: '1rem' }}>
                        <div style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => setSelectedContent('coursesInfo')}>
                            <Typography variant={selectedContent === 'coursesInfo' ? 'h6' : 'body1'} style={{ fontSize: 16 }}>
                                {"Информация за курсове"}
                            </Typography>
                        </div>
                        <div style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => setSelectedContent('coursesRegistration')}>
                            <Typography variant={selectedContent === 'coursesRegistration' ? 'h6' : 'body1'} style={{ fontSize: 16 }}>
                                {"Регистрация"}
                            </Typography>
                        </div>
                    </div>
                    <Divider style={{ background: '#E0F7F2' }} />
                    {coursesContent}
                </div>
            </Container >
        </div >
    )
}

export default CourseContainer;
