import { createTheme } from '@material-ui/core/styles'

export const theme = createTheme({
    palette: {
        primary: {
            main: '#0A8968',
            contrastText: '#ffffff',
        },
    },
    shape: {
        borderRadius: 3
    },
    typography: {
        h6: {
            fontFamily: 'Montserrat-Bold',
            fontSize: 16
        },
        h5: {
            fontFamily: 'Nunito-Bold',
            fontSize: 30,
            letterSpacing: 6,
            color: '#161616',
            fontWeight: 700,
            background: "linear-gradient(90deg, #0FC593 0%, #098363 5%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent"
        },
        h4: {
            fontFamily: 'Montserrat-Bold',
            fontSize: 24,
            color: '#ffffff',
            fontweight: 700,

        },
        body1: {
            fontFamily: 'Montserrat-Regular',
            fontSize: 16
        },
        fontFamily: 'Montserrat-Regular'
    }
});
