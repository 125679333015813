import React, { useEffect, useState, FunctionComponent, useContext } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theming/theme';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { green } from '@material-ui/core/colors';
import { Divider, FormControlLabel, RadioGroup } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { cities } from '../utils';
import { appConfig } from '../../appConfig';
import axios from 'axios';
import Loading from '../Loading/Loading';
import AppContext from '../../context/AppContext';
import { IUserData } from '../../context/AppUserContext';
import NotLoggedInUser from '../NotLoggedInUser';
import GreenRadio from '../Forms/GreenRadio';
import CoursePayments from './CoursePayments';



const useStyles = makeStyles((theme: any) => ({
    paper: {
        // marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
} as any));

const incorrectInput = "Полето е задължително";
const incorrectEmail = "Невалиден email";
const meilRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

interface Props {
    userData?: IUserData;
}

const CourseRegister = (props: Props) => {
    const classes: any = useStyles();

    const [, setIsOpened] = useContext(AppContext);
    const [userData, setUserData] = useState(props.userData || ({} as IUserData));

    const [wrongInput, setWrongInput] = useState('');

    // user data
    const [userId, setUserId] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [midName, setMidName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneCode, setPhoneCode] = useState('');
    const [phone, setPhone] = useState('');

    // course data
    const [dateOfCourse, setDateOfCourse] = useState(new Date());
    const [typeOfCourse, setTypeOfCourse] = useState('');
    const [placeOfCourse, setPlaceOfCourse] = useState('');
    const [courseTopics, setCourseTopics] = useState('');
    const [invoiceData, setInvoiceData] = useState('');

    const [paymentType, setPaymentType] = useState('');

    const [validUserId, setValidUserId] = useState(true);
    const [validFirstName, setValidFirstName] = useState(true);
    const [validLastName, setValidLastName] = useState(true);
    const [validEmail, setValidEmail] = useState(true);
    const [validPhoneCode, setValidPhoneCode] = useState(true);
    const [validPhone, setValidPhone] = useState(true);
    const [validTypeOfCourse, setValidTypeOfCourse] = useState(true);
    // const [validPayment, setValidPayment] = useState(true);

    const [incorrectMeilFormat, setIncorrectMeilFormat] = useState(false);

    const [showSuccess, setShowSuccess] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [error, setError] = useState(false);


    // form valdiation
    useEffect(() => {
        if (userId) {
            setValidUserId(true);
        }
        if (firstName) {
            setValidFirstName(true);
        }
        if (lastName) {
            setValidLastName(true);
        }
        if (email) {
            setValidEmail(true);
        }
        if (phoneCode) {
            setValidPhoneCode(true);
        }
        if (phone) {
            setValidPhone(true);
        }
        if (typeOfCourse) {
            setValidTypeOfCourse(true);
        }
        // if (paymentType && paymentType !== 'empty') {
        //     setValidPayment(true);
        // }

        if ((meilRegex.test(String(email).toLowerCase()))) {
            setIncorrectMeilFormat(false);
        }

    }, [userId, firstName, lastName, email, phoneCode, phone, paymentType, typeOfCourse]);

    useEffect(() => {
        if (wrongInput) {
            if (window.scrollTo) {
                window.scrollTo(0, 0);
            }
        }
    }, [wrongInput]);

    useEffect(() => {
        setUserData(props.userData);
        setFirstName(props.userData.firstName);
        setLastName(props.userData.lastName);
        setUserId(props.userData.userId);
        setEmail(props.userData.email);
    }, [props.userData])

    const onRegister = (e: any) => {
        e.preventDefault();

        if (!userId) {
            setWrongInput('userId');
            setValidUserId(false);
            return;
        }

        if (!firstName) {
            setWrongInput('firstName');
            setValidFirstName(false);
            return;
        }

        if (!lastName) {
            setWrongInput('lastName');
            setValidLastName(false);
            return;
        }

        if (!email) {
            setWrongInput('email');
            setValidEmail(false);
            return;
        }

        if (!phoneCode) {
            setWrongInput('phoneCode');
            setValidPhoneCode(false);
            return;
        }

        if (!phone) {
            setWrongInput('phone');
            setValidPhone(false);
            return;
        }

        if (!(meilRegex.test(String(email).toLowerCase()))) {
            setWrongInput('meilRegex');
            setIncorrectMeilFormat(true);
            return;
        }

        if (!typeOfCourse) {
            setValidTypeOfCourse(false);
            return;
        }

        // if (!paymentType || paymentType === 'empty') {
        //     setValidPayment(false);
        //     return;
        // }

        // send the data

        setShowLoading(true);
        axios.post(appConfig.courseRegisterUrl, {
            name: firstName,
            surname: midName,
            last_name: lastName,
            reg_number: userId,
            email: email,
            telephone: `${phoneCode}${phone}`,
            course_date: getDate(),
            course_type: typeOfCourse,
            course_place: placeOfCourse,
            course_topics: courseTopics,
            invoice_data: invoiceData,
            // payment_type: paymentType,
        }, {
            headers: { 'Authorization': `Token ${userData.appKey}` }
        }).then(r => {
            // console.log(r.data);
            setShowLoading(false);
            setShowSuccess(true);
        }).catch(err => {
            setError(true);
            setShowLoading(false);
            console.error(err);
            if (window.scrollTo) {
                window.scrollTo(0, 0);
            }
        });
    }

    const getDate = () => {
        const year = dateOfCourse.getFullYear();
        const month = ("0" + (dateOfCourse.getMonth() + 1)).slice(-2);
        const day = ("0" + dateOfCourse.getDate()).slice(-2);

        return `${year}-${month}-${day}`;
    };

    const handleTypeOfCourse = (value: EventTarget & { name?: string, value: unknown }) => {
        setTypeOfCourse(value.value as string);
    };

    const handlePlaceOfCourse = (value: EventTarget & { name?: string, value: unknown }) => {
        setPlaceOfCourse(value.value as string);
    };

    const onOpenLogin = (e: any) => {
        e.preventDefault();
        setIsOpened(true);
    }

    const handlePaymentType = (selectedPaymentType: string) => {
        if (selectedPaymentType === paymentType) {
            setPaymentType('empty');
        } else {
            setPaymentType(selectedPaymentType);
        }
    }

    return (
        <div style={{ backgroundColor: '#F6FFFD', padding: '2rem' }}>
            {/* <Container component="main" maxWidth="md"> */}
            {/* <ThemeProvider theme={theme}> */}
            <>
                {
                    showSuccess ?
                        <div className={classes.paper} style={{ textAlign: 'center' }}>
                            <div>
                                <CheckCircleRoundedIcon style={{ width: '15rem', height: '15rem', color: green[500] }} />

                            </div>
                            <Typography variant={"h6"} style={{ fontSize: 18, color: '#098363', fontWeight: 700 }}>
                                {`Успешна регистрация! Правоспособно лице с рег. номер ${userId} беше успешно регистрирано!`}
                            </Typography>
                        </div>
                        :
                        showLoading
                            ?
                            <Loading show={showLoading} />
                            :
                            Object.keys(userData).length
                                ?
                                <>
                                    {
                                        error
                                            ?
                                            <div style={{ width: '100%', textAlign: 'center', color: 'red' }}>
                                                {"Възникна грешка. Моля, опитайте отново!"}
                                            </div>
                                            :
                                            null
                                    }
                                    <>
                                        <div className={classes.paper}>
                                            <Typography component="div" variant="h6" style={{ fontSize: 20, color: '#098363', fontWeight: 700 }}>
                                                {"Регистрация"}
                                            </Typography>
                                            <br />
                                            <Typography variant="body1" style={{ maxWidth: '75%', textAlign: 'center', fontSize: 16, fontWeight: 400, color: '#161616' }}>
                                                {"за участие в курс за повишаване на квалификацията на правоспособни лица по кадастър (чл. 20, ал. 4 и 5 от ЗКИР)"}
                                            </Typography>
                                            <form className={classes.form} noValidate>
                                                <div>
                                                    <Typography variant="h6" gutterBottom style={{ fontWeight: 700, fontSize: 14 }}>
                                                        {"Правоспособно лице по кадастър"}
                                                    </Typography>
                                                    <Grid container spacing={4}>
                                                        <Grid item xs={12} sm={6}>
                                                            <TextField
                                                                size={"small"}
                                                                value={firstName}
                                                                onChange={(e) => setFirstName(e.target.value)}
                                                                autoComplete="fname"
                                                                name="firstName"
                                                                variant="outlined"
                                                                required
                                                                fullWidth
                                                                id="firstName"
                                                                label="Име"
                                                                disabled={userData.firstName ? true : false}
                                                                style={{ background: 'white' }}
                                                                error={!validFirstName ? true : false}
                                                                helperText={!validFirstName ? incorrectInput : ""}
                                                            />
                                                        </Grid>
                                                        {/* <Grid item xs={12} sm={4}>
                                                        <TextField
                                                            size={"small"}
                                                            value={midName}
                                                            onChange={(e) => setMidName(e.target.value)}
                                                            autoComplete="midname"
                                                            name="midName"
                                                            variant="outlined"
                                                            required
                                                            fullWidth
                                                            id="midName"
                                                            label="Презиме"
                                                            style={{ background: 'white' }}
                                                        // error={!validFirstName ? true : false}
                                                        // helperText={!validFirstName ? incorrectInput : ""}
                                                        />
                                                    </Grid> */}
                                                        <Grid item xs={12} sm={6}>
                                                            <TextField
                                                                size={"small"}
                                                                value={lastName}
                                                                onChange={(e) => setLastName(e.target.value)}
                                                                variant="outlined"
                                                                required
                                                                fullWidth
                                                                id="lastName"
                                                                label="Фамилия"
                                                                name="lastName"
                                                                autoComplete="lname"
                                                                disabled={userData.lastName ? true : false}
                                                                style={{ background: 'white' }}
                                                                error={!validLastName ? true : false}
                                                                helperText={!validLastName ? incorrectInput : ""}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <br />
                                                <div>
                                                    <Grid container spacing={5}>
                                                        <Grid item xs={12} sm={6}>
                                                            <Typography variant="h6" gutterBottom style={{ fontWeight: 700, fontSize: 14 }}>
                                                                {"Регистрационен номер *"}
                                                            </Typography>
                                                            <TextField
                                                                size={"small"}
                                                                value={userId}
                                                                onChange={(e) => setUserId(e.target.value)}
                                                                autoComplete="userGeoId"
                                                                name="userGeoId"
                                                                variant="outlined"
                                                                // required
                                                                fullWidth
                                                                id="userGeoId"
                                                                disabled={userData.userId ? true : false}
                                                                style={{ background: 'white' }}
                                                                error={!validUserId ? true : false}
                                                                helperText={!validUserId ? incorrectInput : ""}
                                                            />
                                                            <Typography variant="body1" style={{ fontSize: 12, fontWeight: 400 }}>
                                                                {"Моля, въведете номер от регистъра на правоспособните физически лица по кадастър"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <Typography variant="h6" gutterBottom style={{ fontWeight: 700, fontSize: 14 }}>
                                                                {"E-mail *"}
                                                            </Typography>
                                                            <TextField
                                                                size={"small"}
                                                                value={email}
                                                                onChange={(e) => setEmail(e.target.value)}
                                                                autoComplete="email"
                                                                name="userEmail"
                                                                variant="outlined"
                                                                required
                                                                fullWidth
                                                                id="userEmail"
                                                                label=""
                                                                style={{ background: 'white' }}
                                                                error={!validEmail || incorrectMeilFormat ? true : false}
                                                                helperText={!validEmail ? incorrectInput : incorrectMeilFormat ? incorrectEmail : ''}
                                                            />
                                                            <Typography variant="body1" style={{ fontSize: 12, fontWeight: 400 }}>
                                                                {"Моля, въведете коректен e-mail адрес за връзка с Вас"}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <br />
                                                <div>
                                                    <Grid container spacing={4}>
                                                        <Grid item xs={12} sm={6}>
                                                            <Typography variant="h6" gutterBottom style={{ fontWeight: 700, fontSize: 14 }}>
                                                                {"Телефон *"}
                                                            </Typography>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12} sm={4}>
                                                                    <TextField
                                                                        size={"small"}
                                                                        value={phoneCode}
                                                                        onChange={(e) => setPhoneCode(e.target.value)}
                                                                        autoComplete="phoneCode"
                                                                        name="phoneCode"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        id="phoneCode"
                                                                        label="Код"
                                                                        style={{ background: 'white' }}
                                                                        error={!validPhoneCode ? true : false}
                                                                        helperText={!validPhoneCode ? incorrectInput : ""}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={8}>
                                                                    <TextField
                                                                        size={"small"}
                                                                        value={phone}
                                                                        onChange={(e) => setPhone(e.target.value)}
                                                                        autoComplete="phone"
                                                                        name="phone"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        id="phone"
                                                                        label="Номер"
                                                                        style={{ background: 'white', width: '97%' }}
                                                                        error={!validPhone ? true : false}
                                                                        helperText={!validPhone ? incorrectInput : ""}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <Typography variant="h6" gutterBottom style={{ fontWeight: 700, fontSize: 14 }}>
                                                                {"Форма на провеждане *"}
                                                            </Typography>
                                                            <FormControl fullWidth style={{}}>
                                                                <InputLabel id="simple-course-type" />
                                                                <Select
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    labelId="simple-course-type"
                                                                    id="simple-course-type-select"
                                                                    value={typeOfCourse}
                                                                    onChange={(e) => { handleTypeOfCourse(e.target) }}
                                                                    style={{ background: 'white', maxHeight: '2.5rem' }}
                                                                    inputProps={{ size: 'small' }}
                                                                >
                                                                    <MenuItem value={"Онлайн курс"}>{"Онлайн курс"}</MenuItem>
                                                                    <MenuItem value={"Присъствен"}>{"Присъствен"}</MenuItem>
                                                                    <MenuItem value={"Самообучение"}>{"Самообучение"}</MenuItem>
                                                                </Select>
                                                                {
                                                                    !validTypeOfCourse
                                                                        ?
                                                                        <div style={{ color: 'red' }}>{incorrectInput}</div>
                                                                        :
                                                                        null
                                                                }
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <br />
                                                <div>
                                                    <Grid container spacing={4}>
                                                        <Grid item xs={12} sm={12}>
                                                            <Typography variant="h6" gutterBottom style={{ fontWeight: 700, fontSize: 14 }}>
                                                                {"Данни за фактура"}
                                                            </Typography>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12} sm={12}>
                                                                    <TextField
                                                                        size={"small"}
                                                                        value={invoiceData}
                                                                        onChange={(e) => setInvoiceData(e.target.value)}
                                                                        autoComplete="invoiceData"
                                                                        name="invoiceData"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        multiline
                                                                        minRows={3}
                                                                        maxRows={3}
                                                                        id="invoiceData"
                                                                        label=""
                                                                        style={{ background: 'white' }}
                                                                    // error={!validPhone ? true : false}
                                                                    // helperText={!validPhone ? incorrectInput : ""}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <Divider style={{ marginTop: '3rem', marginBottom: '2rem' }} />
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={6}>
                                                        <Typography variant="h6" gutterBottom style={{ fontWeight: 700, fontSize: 14 }}>
                                                            {"Дата за провеждане на курс"}
                                                        </Typography>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                inputVariant="outlined"
                                                                disableToolbar
                                                                variant="inline"
                                                                format="dd/MM/yyyy"
                                                                margin="normal"
                                                                id="id-course-data"
                                                                label=""
                                                                value={dateOfCourse}
                                                                onChange={(e) => { setDateOfCourse(e) }}
                                                                autoOk={true}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                                style={{ marginTop: '0.5rem', background: 'white' }}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Typography variant="h6" gutterBottom style={{ fontWeight: 700, fontSize: 14 }}>
                                                            {"Място за провеждане на курс"}
                                                        </Typography>
                                                        <FormControl fullWidth style={{ marginTop: '0.5rem' }}>
                                                            <InputLabel id="course-place-label" />
                                                            <Select
                                                                variant="outlined"
                                                                fullWidth
                                                                labelId="course-place-label"
                                                                id="course-place-select"
                                                                value={placeOfCourse}
                                                                onChange={(e) => { handlePlaceOfCourse(e.target) }}
                                                                style={{ background: 'white' }}
                                                            >
                                                                {cities.map((city, i) => (
                                                                    <MenuItem key={`${city}_${i}`} value={city}>{city}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12}>
                                                        <Typography variant="h6" gutterBottom style={{ fontWeight: 700, fontSize: 14 }}>
                                                            {"Посочете интересуващи Ви теми за включване в курса"}
                                                        </Typography>
                                                        <TextField
                                                            key={"courseTopicsTextField"}
                                                            value={courseTopics}
                                                            onChange={(e) => setCourseTopics(e.target.value)}
                                                            autoComplete="fname"
                                                            name="firstName"
                                                            variant="outlined"
                                                            multiline
                                                            minRows={6}
                                                            maxRows={6}
                                                            fullWidth
                                                            id="firstNamess"
                                                            label=""
                                                            error={!validFirstName ? true : false}
                                                            helperText={!validFirstName ? incorrectInput : ""}
                                                            style={{ background: 'white' }}
                                                        />
                                                        <Typography variant="body1" style={{ fontSize: 12, fontWeight: 400 }}>
                                                            {"Предложенията Ви ще се вземат предвид при окончателно съставяне на програма за обучение."}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Divider style={{ marginTop: '3rem', marginBottom: '2rem' }} />
                                                <CoursePayments />

                                                {/* <div>
                                                    <Typography variant="h6" gutterBottom style={{ fontWeight: 700, fontSize: 14 }}>
                                                        {"Отбележете начина на плащане"}
                                                    </Typography>
                                                    <RadioGroup aria-label="position" name="position" defaultValue="top" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <FormControlLabel
                                                            value="top"
                                                            control={
                                                                <GreenRadio
                                                                    checked={paymentType === 'BANK'}
                                                                    onClick={() => { handlePaymentType('BANK') }}
                                                                    value="email"
                                                                    name="radio-button-email"
                                                                />
                                                            }
                                                            label="Платежно нареждане"
                                                        />
                                                        <FormControlLabel
                                                            value="top"
                                                            control={
                                                                <GreenRadio
                                                                    checked={paymentType === 'EPAY'}
                                                                    onClick={() => { handlePaymentType('EPAY') }}
                                                                    value="address"
                                                                    name="radio-button-address"
                                                                />
                                                            }
                                                            label="Он-лайн - EPay"
                                                        />
                                                        <FormControlLabel
                                                            value="top"
                                                            control={
                                                                <GreenRadio
                                                                    checked={paymentType === 'PAYPAL'}
                                                                    onClick={() => { handlePaymentType('PAYPAL') }}
                                                                    value="address"
                                                                    name="radio-button-address"
                                                                />
                                                            }
                                                            label="Он-лайн - PayPal"
                                                        />
                                                        <FormControlLabel
                                                            value="top"
                                                            control={
                                                                <GreenRadio
                                                                    checked={paymentType === 'MYPOS'}
                                                                    onClick={() => { handlePaymentType('MYPOS') }}
                                                                    value="address"
                                                                    name="radio-button-address"
                                                                />
                                                            }
                                                            label="Он-лайн - myPos"
                                                        />
                                                    </RadioGroup>
                                                    {
                                                        !validPayment
                                                            ?
                                                            <div style={{ width: '100%', textAlign: 'center', color: 'red' }}>
                                                                {"Моля изберете начина, по който сте заплатили Вашата такса!"}
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div> */}
                                                <div style={{ width: '100%', textAlign: 'center' }}>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        className={classes.submit}
                                                        onClick={(e) => { onRegister(e) }}
                                                        style={{ background: "linear-gradient(90deg, #0FC593 0%, #098363 100%)" }}
                                                    >
                                                        <Typography variant={"h6"} style={{ fontSize: 16, fontWeight: 700 }}>
                                                            {"Регистрация"}
                                                        </Typography>
                                                    </Button>
                                                </div>
                                            </form>
                                        </div>
                                    </>
                                </>
                                :
                                <NotLoggedInUser onOpenLogin={onOpenLogin} notLoggedInUserMessage={"За да се регистрирате за провеждане на курс, първо трябва да влезете в системата!"} />
                }
            </>
            {/* </ThemeProvider> */}
            {/* </Container> */}
        </div>
    );
}

export default CourseRegister;
