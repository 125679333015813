import * as React from 'react';
import { useState, useEffect } from 'react';
import { Container, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '@reach/router';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(1.5)',
        color: '#0fc594'
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

interface Props {
    currentRoute: string
}

const Footer = (props: Props) => {
    const classes = useStyles();
    const bull = <span className={classes.bullet}>•</span>;

    const [currentPath, setCurrentPath] = useState(props.currentRoute);

    useEffect(() => {
        setCurrentPath(props.currentRoute);
    }, [props.currentRoute]);

    return (
        <>
            {
                currentPath !== 'test'
                    ?
                    <div style={{ width: '100%', backgroundColor: '#012A1F' }}>
                        <Container component="div" maxWidth="lg" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <div style={{ flex: '1 0 auto' }}>
                                <div style={{ textAlign: 'center', color: 'white', marginTop: '2rem' }}>
                                    <Typography variant="h6" style={{ fontSize: 24, color: '#ffffff', fontWeight: 700 }}>
                                        {"Връзки"}
                                    </Typography>
                                </div>
                                <div style={{ display: 'flex', color: 'white', justifyContent: 'center', marginTop: '1.3rem' }}>
                                    <div style={{ width: '80%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '1rem' }}>
                                            <div style={{ display: 'inline-block', margin: '0.3rem 0' }}>
                                                <div style={{ display: 'flex' }}>
                                                    {bull}
                                                    <Typography variant="body1" style={{ fontSize: 14, display: 'inline-block', marginLeft: '1.0rem' }}>
                                                        <a style={{ textDecoration: 'none', color: 'inherit' }} href={"https://www.cadastre.bg/"} target={"__blank"} >
                                                            {"Агенция по геодезия, картография и кадастър"}
                                                        </a>
                                                    </Typography>
                                                </div>
                                            </div>
                                            <div style={{ display: 'inline-block', margin: '0.3rem 0' }}>
                                                <div style={{ display: 'flex' }}>
                                                    {bull}
                                                    <Typography variant="body1" style={{ fontSize: 14, display: 'inline-block', marginLeft: '1.0rem' }}>
                                                        <a style={{ textDecoration: 'none', color: 'inherit' }} href={"https://geodesy-union.org/"} target={"__blank"} >
                                                            {"Съюз на геодезистите и земеустроителите в България"}
                                                        </a>
                                                    </Typography>
                                                </div>
                                            </div>
                                            <div style={{ display: 'inline-block', margin: '0.3rem 0' }}>
                                                <div style={{ display: 'flex' }}>
                                                    {bull}
                                                    <Typography variant="body1" style={{ fontSize: 14, display: 'inline-block', marginLeft: '1.0rem' }}>
                                                        <a style={{ textDecoration: 'none', color: 'inherit' }} href={"http://mgu.bg/new/index.php"} target={"__blank"} >
                                                            {"Минно-геоложки университет \"СВ. Иван Рилски\""}
                                                        </a>
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div style={{ display: 'inline-block', margin: '0.3rem 0' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {bull}
                                                    <Typography variant="body1" style={{ fontSize: 14, display: 'inline-block', marginLeft: '1.0rem' }}>
                                                        <a style={{ textDecoration: 'none', color: 'inherit' }} href={"https://kiip.bg/"} target={"__blank"} >
                                                            {"Камара на инженерите в инвестиционното проектиране (КИИП)"}
                                                        </a>
                                                    </Typography>
                                                </div>
                                            </div>

                                            <div style={{ display: 'inline-block', margin: '0.3rem 0' }}>
                                                <div style={{ display: 'flex' }}>
                                                    {bull}
                                                    <Typography variant="body1" style={{ fontSize: 14, display: 'inline-block', marginLeft: '1.0rem' }}>
                                                        <a style={{ textDecoration: 'none', color: 'inherit' }} href={"https://uacg.bg/"} target={"__blank"} >
                                                            {"Университет по Архитектура, Строителство и Геодезия (УАСГ)"}
                                                        </a>
                                                    </Typography>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Divider style={{ backgroundColor: 'white', marginTop: '2rem', width: '75%', marginLeft: '12%' }} />

                            <div style={{ flexShrink: 0, textAlign: 'center', marginBottom: '1rem', marginTop: '1rem' }}>
                                <Typography variant="body1" component="div" style={{ color: '#959595', fontSize: 14, fontWeight: 400 }}>
                                    {"Copyright 2021. ОБУЧЕНИЕ НА ПРАВОСПОСОБНИ ЛИЦА ПО ЗАКОНА ЗА КАДАСТЪРА И ИМОТНИЯ РЕГИСТЪР"}
                                </Typography>
                            </div>

                        </Container>
                    </div>
                    :
                    null
            }
        </>

    )
}

export default Footer;
