import * as React from 'react';
import { useState, CSSProperties } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import Collapse from '@material-ui/core/Collapse';
import { rotatedArrowStyle, arrowStyle, showAllTextCss } from '../utils';


interface Props {
    title: string;
    date: string;
    place: string;
    description: string;
}


const useStyles = makeStyles({
    root: {
        minWidth: 275,
        borderRadius: 15,
        filter: 'drop-shadow(0 0 7.5px rgba(0,0,0,0.15))',
        backgroundColor: '#ffffff',
        borderColor: '#ffffff'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 18,
        fontWeight: 700,
        marginBottom: '1rem'
    },
    separator: {
        marginLeft: '1rem',
        marginRight: '1rem'
    },
    pos: {
        marginBottom: 12,
    },
});

const CourseInfo = (props: Props) => {
    const classes = useStyles();

    const [isShowAll, setIsShowAll] = useState(false);

    const showAllText = () => {
        setIsShowAll(!isShowAll);
    }

    return (
        <Card className={classes.root} variant="outlined">
            <CardContent>
                <Typography className={classes.title} variant="h6" component="div">
                    {props.title}
                </Typography>
                <div style={{ display: 'flex', marginBottom: '1rem' }}>
                    <Typography variant="body1" component="div" style={{ fontSize: 16, fontWeight: 400 }}>
                        {'Дата: '}
                        <span style={{ fontWeight: 700, color: '#098363' }}>
                            {props.date}
                        </span>
                    </Typography>
                    <Typography className={classes.separator} variant="body2" component="p">
                        {'|'}
                    </Typography>
                    <Typography variant="body1" component="div" style={{ fontSize: 16, fontWeight: 400 }}>
                        {'Място: '}
                        <span style={{ fontWeight: 700, color: '#098363' }}>
                            {props.place}
                        </span>
                    </Typography>
                </div>
                <Collapse in={isShowAll} collapsedSize={'7rem'}>
                    <Typography variant="body1" component="p" style={showAllTextCss}>
                        {props.description}
                    </Typography>
                </Collapse>
            </CardContent>
            <CardActions style={{ marginLeft: 'auto', position: 'relative' }}>
                <Button style={{ marginLeft: 'auto', padding: '4px 17px' }} size="small" onClick={showAllText}>
                    <Typography style={{ fontSize: 14, color: '#2E8D71' }} variant="body1" component="p">
                        {isShowAll ? "Скрий информация" : "Повече информация"}
                    </Typography>
                </Button>
                <div style={{ position: 'relative', width: '5%', height: '100%', cursor: 'pointer' }} onClick={showAllText}>
                    <div style={{
                        background: "linear-gradient(90deg, #098363 0%, #0FC593 100%)",
                        width: '3rem',
                        height: '3rem',
                        position: 'absolute',
                        right: -10,
                        bottom: 0,
                        top: -20,
                        borderTopLeftRadius: 15
                    }}>
                        <div style={{ position: 'relative' }}>
                            <ArrowForwardIosRoundedIcon style={isShowAll ? rotatedArrowStyle : arrowStyle} />
                        </div>
                    </div>

                </div>
            </CardActions>
        </Card>
    )
}

export default CourseInfo;
