import * as React from 'react';
import { CSSProperties } from 'react';
import { Divider, Typography } from '@material-ui/core';
import { showAllTextCss } from '../utils';


interface Props {
    user: string;
    description: string;
}

const ForumComment = (props: Props) => {

    const showAllTextCssUser: CSSProperties = { ...showAllTextCss, textAlign: 'end' }
    const showAllTextCssComment = { ...showAllTextCss, paddingLeft: '1.5rem' }

    return (
        <div style={{
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: '#E0F7F2',
            borderRadius: '15px',
            margin: '1rem 0px',
            padding: '0.5rem',
            marginLeft: '2rem'
        }}>
            <Typography variant="body1" component="p" style={showAllTextCssComment}>
                {props.description}
            </Typography>
            <Divider style={{ background: '#E0F7F2' }} />
            <Typography variant="body1" component="p" style={showAllTextCssUser}>
                {`${props.user}`}
            </Typography>
        </div>
    )
}

export default ForumComment;
