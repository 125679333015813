import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import { getAppConfig } from "./appConfig";
import './css/index.css';

const rootEl = document.getElementById("root");

getAppConfig('static/config.json').then(c => {
    ReactDOM.render(<App />, rootEl);
}).catch(err => {
    console.error(err);
});
