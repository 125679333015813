import * as React from 'react';
import { useState, useContext, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import PersonOutlineRoundedIcon from '@material-ui/icons/PersonOutlineRounded';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from '@reach/router';
import { Divider } from '@material-ui/core';
import DoubleArrowRoundedIcon from '@material-ui/icons/DoubleArrowRounded';
import HomeButton from '../../assets/home.svg';
import NewsButton from '../../assets/news.svg';
import Comment from '../../assets/comment.svg';
import MaterialsButton from '../../assets/materials.svg';
import CourseButton from '../../assets/courses.svg';
import TestPage from '../../assets/test.svg';
import CertificateButton from '../../assets/certificate.svg';
import AppUserContext, { IUserData } from 'src/context/AppUserContext';
import { eraseCookie } from '../utils';
import RouteContext from '../../context/RouteContext';
import AppContext from '../../context/AppContext';
import { headerStyles } from './headerStyles';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(headerStyles);


interface Props {
    currentRoute: string;
    userData: IUserData;
    onDrawerClose: (value: boolean) => void;
}

const HeaderButtons = (props: Props) => {
    const classes = useStyles();

    const [userData, setLocalUserData] = useState(props.userData);
    const [openUserMenu, setOpenUserMenu] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [, setCurrentPath] = useContext(RouteContext);
    const [, setIsOpened] = useContext(AppContext);
    const [, setUserData] = useContext(AppUserContext);


    useEffect(() => {
        setLocalUserData(props.userData);
    }, [props.userData]);

    const handleClose = () => {
        setOpenUserMenu(false);
        setAnchorEl(null);
    };

    const openMenu = (event: React.MouseEvent<HTMLDivElement>) => {
        if (userData && userData.appKey) {
            if (!openUserMenu) {
                setAnchorEl(event.currentTarget);
            }
            setOpenUserMenu(!openUserMenu);
        } else {
            setIsOpened(true);
        }
    }

    const onExit = () => {
        eraseCookie('annual_geo_test');
        handleClose();
        setUserData({} as IUserData);
        setLocalUserData({} as IUserData);
    }

    const onItemClick = (path: string) => {
        props.onDrawerClose(false);
        setCurrentPath(path);
    }

    return (
        <>
            <Link to={"/"} className={classes.linkButton} onClick={() => onItemClick("/")}>
                <div style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                    <img src={HomeButton} style={{ width: '2rem', height: '2rem' }} />
                    <Typography className={classes.title} variant="h6" noWrap>
                        {"Начало"}
                    </Typography>
                </div>
                {
                    props.currentRoute === '/'
                        ?
                        <Divider style={{ marginTop: '0.3rem', height: '0.3rem', background: '#098363' }} />
                        :
                        null
                }
            </Link>
            <Link to={"newsfeed"} className={classes.linkButton} onClick={() => onItemClick("newsfeed")}>
                <div style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                    <img src={NewsButton} style={{ width: '2rem', height: '2rem' }} />
                    <Typography className={classes.title} variant="h6" noWrap>
                        {"Новини"}
                    </Typography>
                </div>
                {
                    props.currentRoute === 'newsfeed'
                        ?
                        <Divider style={{ marginTop: '0.3rem', height: '0.3rem', background: '#098363' }} />
                        :
                        null
                }
            </Link>
            <Link to={"forum"} className={classes.linkButton} onClick={() => onItemClick("forum")}>
                <div style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                    <img src={Comment} style={{ width: '2rem', height: '2rem' }} />
                    <Typography className={classes.title} variant="h6" noWrap>
                        {"Форум"}
                    </Typography>
                </div>
                {
                    props.currentRoute === 'forum'
                        ?
                        <Divider style={{ marginTop: '0.3rem', height: '0.3rem', background: '#098363' }} />
                        :
                        null
                }
            </Link>
            <Link to={"documents-and-materials"} className={classes.linkButton} onClick={() => onItemClick("documents-and-materials")}>
                <div style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                    <img src={MaterialsButton} style={{ width: '2rem', height: '2rem' }} />
                    <Typography className={classes.title} variant="h6" noWrap>
                        {"Материали"}
                    </Typography>
                </div>
                {
                    props.currentRoute === 'documents-and-materials'
                        ?
                        <Divider style={{ marginTop: '0.3rem', height: '0.3rem', background: '#098363' }} />
                        :
                        null
                }
            </Link>
            <Link to={"courses"} className={classes.linkButton} onClick={() => onItemClick("courses")}>
                <div style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                    <img src={CourseButton} style={{ width: '2rem', height: '2rem' }} />
                    <Typography className={classes.title} variant="h6" noWrap>
                        {"Курсове"}
                    </Typography>
                </div>
                {
                    props.currentRoute === 'courses'
                        ?
                        <Divider style={{ marginTop: '0.3rem', height: '0.3rem', background: '#098363' }} />
                        :
                        null
                }
            </Link>
            <Link to={"test-page"} className={classes.linkButton} onClick={() => onItemClick("test-page")}>
                <div style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                    <img src={TestPage} style={{ width: '2rem', height: '2rem' }} />
                    <Typography className={classes.title} variant="h6" noWrap>
                        {"Тестове"}
                    </Typography>
                </div>
                {
                    props.currentRoute === 'test-page'
                        ?
                        <Divider style={{ marginTop: '0.3rem', height: '0.3rem', background: '#098363' }} />
                        :
                        null
                }
            </Link>
            <Link to={"certificate"} className={classes.linkButton} onClick={() => onItemClick("certificate")}>
                <div style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                    <img src={CertificateButton} style={{ width: '2rem', height: '2rem' }} />
                    <Typography className={classes.title} variant="h6" noWrap>
                        {"Удостоверение"}
                    </Typography>
                </div>
                {
                    props.currentRoute === 'certificate'
                        ?
                        <Divider style={{ marginTop: '0.3rem', height: '0.3rem', background: '#098363' }} />
                        :
                        null
                }
            </Link>
            <div className={classes.userButton} onClick={(e) => { openMenu(e) }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <PersonOutlineRoundedIcon className={classes.userIcon} />
                    <Typography style={{ display: 'inline-block', whiteSpace: 'nowrap', overflow: 'hidden !important', textOverflow: 'ellipsis' }} className={classes.title} variant="h6" noWrap>
                        {
                            userData.appKey
                                ?
                                "Профил"
                                :
                                "Вход / Регистрация"
                        }
                    </Typography>
                </div>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={openUserMenu}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClose}>{`Име: ${userData.firstName} ${userData.lastName}`}</MenuItem>
                    <MenuItem onClick={onExit}>{`Изход`}</MenuItem>
                </Menu>
            </div>
            <div className={classes.closeMenuButton}>
                <div style={{ width: '3rem', height: '3rem' }} onClick={() => props.onDrawerClose(false)}>
                    <DoubleArrowRoundedIcon style={{ color: '#098363', width: '100%', height: '100%', cursor: 'pointer' }} />
                </div>
            </div>
        </>
    )
}

export default HeaderButtons;
