import * as React from "react";
import { useEffect, useState, useContext } from 'react';
import { ThemeProvider } from "@material-ui/core";
import { Router, navigate, createHistory, LocationProvider, HistorySource, globalHistory } from '@reach/router';
import createHashSource from 'hash-source';
import { theme } from './theming/theme';
// import Home from './components/App/Home';
import SignIn from "./components/Register/SignIn";
import SignUp from "./components/Register/SignUp";
import HomePage from "./components/HomePage";
import TopHeader from "./components/Header/TopHeader";
import Header from "./components/Header/Header";
import Footer from './components/App/Footer';
import CertificatesContainer from './components/Certificate/CertificatesContainer';
import DocumentsAndMaterials from "./components/Documents/DocumentsAndMaterials";
import NewsFeed from "./components/News/NewsFeed";
import AppDialog from "./components/AppDialog";
import ResetPassword from "./components/Register/ResetPassword";
import SetNewPassword from "./components/Register/SetNewPassword";
import AppContext from "./context/AppContext";
import TestContainer from "./components/Test/TestContainer";
import RouteContext from "./context/RouteContext";
import CourseContainer from "./components/Courses/CourseContainer";
import AppUserContext from "./context/AppUserContext";
import TestInfoContext, { ITestInfo } from "./context/TestInfoContext";
import { getDataFromCookie } from "./components/utils";
import axios from "axios";
import { appConfig } from "./appConfig";
import TestPage from "./components/Test/TestPage";
import ForumContainer from "./components/Forum/ForumContainer";


const source = createHashSource();
const history = createHistory(source as HistorySource);

const retrievedUserData = getDataFromCookie();
let pathKeeper = '';

const App = (): JSX.Element => {
    const [open, setOpen] = useState(false);
    const [path, setPath] = useState('');
    const [userData, setUserData] = useState(retrievedUserData);
    const [testInfo, setTestInfo] = useState<ITestInfo>();
    // const [testInfo, setTestInfo] = useContext(TestInfoContext);


    useEffect(() => {
        // getAppConfig('./config/')
        // console.log(configPath)

        // globalHistory.listen((ev) => {
        //     console.log(ev)
        // });

        axios.get(appConfig.testAnnouncement).then(r => {
            const testAnnouncementData = r.data;
            if (testAnnouncementData && testAnnouncementData.length) {
                setTestInfo({
                    begin_date: testAnnouncementData[0]['begin_date'],
                    end_date: testAnnouncementData[0]['end_date'],
                    title: testAnnouncementData[0]['title'],
                    content: testAnnouncementData[0]['content']
                });
            }
        }).catch(err => {
            console.error(err);
        });


    }, [])

    useEffect(() => {
        if (path) {
            pathKeeper = path;
        }
    }, [path]);

    useEffect(() => {
        if (pathKeeper) {
            setPath(pathKeeper);
        }
    }, [open]);

    const onSetRoute = (currPath: string) => {
        setPath(currPath);
    }

    return (
        <ThemeProvider theme={theme}>
            <LocationProvider history={history}>
                <RouteContext.Provider value={[path, setPath]}>
                    <RouteContext.Consumer>
                        {([currentPath]) => (
                            <>
                                <AppUserContext.Provider value={[userData, setUserData]}>
                                    <AppContext.Provider value={[open, setOpen]}>
                                        <TestInfoContext.Provider value={[testInfo, setTestInfo]}>
                                            <AppContext.Consumer>
                                                {([isOpened]) => (
                                                    <AppDialog isOpened={isOpened} content={<SignIn />} />
                                                )}
                                            </AppContext.Consumer>
                                            <AppUserContext.Consumer>
                                                {([currentUserData]) => (
                                                    <TopHeader currentRoute={currentPath} userData={currentUserData} />
                                                )}
                                            </AppUserContext.Consumer>
                                            <Header currentRoute={currentPath} content={["ОБУЧЕНИЕ НА ПРАВОСПОСОБНИ ЛИЦА ПО ЗАКОНА ЗА КАДАСТЪРА И ИМОТНИЯ РЕГИСТЪР"]} />
                                            <AppUserContext.Consumer>
                                                {([currentUserData]) => (
                                                    <TestInfoContext.Consumer>
                                                        {([currentTestInfo]) => (
                                                            <Router style={{ height: '100%' }}>
                                                                <HomePage path="/" changeCurrentRoute={onSetRoute} testInfo={testInfo} />
                                                                {/* <Home path="home" navigate={navigate} /> */}
                                                                <NewsFeed path="newsfeed" changeCurrentRoute={onSetRoute} userData={userData} />
                                                                <ForumContainer path="forum" changeCurrentRoute={onSetRoute} userData={userData} />
                                                                <DocumentsAndMaterials path="documents-and-materials" changeCurrentRoute={onSetRoute} userData={currentUserData} testInfo={currentTestInfo} />
                                                                <CourseContainer path="courses" changeCurrentRoute={onSetRoute} navigate={navigate} userData={currentUserData} />
                                                                <TestPage path="test-page" changeCurrentRoute={onSetRoute} userData={userData} navigate={navigate} testInfo={currentTestInfo} />
                                                                <CertificatesContainer path="certificate" changeCurrentRoute={onSetRoute} userData={userData} navigate={navigate} />
                                                                <SignIn path="signin" changeCurrentRoute={onSetRoute} />
                                                                <SignUp path="sign-up" userData={userData} navigate={navigate} />

                                                                <TestContainer path="test" userData={currentUserData} testInfo={testInfo} />
                                                                <ResetPassword path="reset" />
                                                                <SetNewPassword path="set-password" />
                                                                {/* <Resources path="resources" navigate={navigate} />
                                                                <SetNewPassword path="set-password" navigate={navigate} /> */}
                                                            </Router>
                                                        )}
                                                    </TestInfoContext.Consumer>
                                                )}
                                            </AppUserContext.Consumer>
                                        </TestInfoContext.Provider>
                                    </AppContext.Provider>

                                </AppUserContext.Provider>
                                <Footer currentRoute={currentPath} />
                            </>
                        )}
                    </RouteContext.Consumer>
                </RouteContext.Provider>
            </LocationProvider>
        </ThemeProvider>

    )
}

export default App;
