import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
// import CourseRegister from './Courses/CourseRegister';
import AppContext from '../context/AppContext';


type Props = {
    isOpened: boolean;
    content: JSX.Element;
    handleClose?: () => void;
}


const AppDialog = (props: Props) => {
    const [open, setOpen] = useState(props.isOpened);
    const [, setIsOpened] = useContext(AppContext);

    useEffect(() => {
        setOpen(props.isOpened);
    }, [props.isOpened]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        props.handleClose ? props.handleClose() : setIsOpened(false);
    };

    return (
        <div>
            <Dialog
                style={{ paddingTop: '5rem' }}
                fullWidth
                maxWidth={"lg"}
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                PaperProps={{ style: { borderRadius: 15 } }}
            >
                <DialogContent style={{ padding: '1.5rem 0px' }}>
                    <div style={{ marginBottom: '2rem' }}>
                        {props.content}
                    </div>
                    {/* <CourseRegister /> */}
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default AppDialog;
