import { createContext } from 'react';

export interface IUserData {
    firstName: string;
    lastName: string;
    userId: string;
    email: string;
    appKey: string;
    user_pk: number;
}


const AppUserContext = createContext<[IUserData, (userData: IUserData) => void]>([{} as IUserData, () => { }]);

export default AppUserContext;
