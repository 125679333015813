import * as React from 'react';
import { useEffect, useState, useContext } from 'react';
import { RouteComponentProps, NavigateFn } from '@reach/router';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../theming/theme';
import RouteContext from '../../context/RouteContext';
import { appConfig } from '../../appConfig';
import axios from 'axios';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import Loading from '../Loading/Loading';
import { IUserData } from '../../context/AppUserContext';


const useStyles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    // avatar: {
    //     margin: theme.spacing(1),
    //     backgroundColor: theme.palette.secondary.main,
    // },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const incorrectInput = "Полето е задължително";
const incorrectEmail = "Невалиден e-mail";
const incorrectRepeatPassowrdLabel = "Паролите не съвпадат";
const meilRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

type Props = RouteComponentProps & {
    userData: IUserData;
    navigate: NavigateFn;
};

const SignUp = (props: Props) => {
    const classes = useStyles();

    const [firstName, setFirstName] = useState('');
    const [midName, setMidName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');

    const [, setCurrentPath] = useContext(RouteContext);

    const [validUserId, setValidUserId] = useState(true);
    const [validFirstName, setValidFirstName] = useState(true);
    const [validMidName, setValidMidName] = useState(true);
    const [validLastName, setValidLastName] = useState(true);
    const [validEmail, setValidEmail] = useState(true);
    const [validPassword, setValidPassword] = useState(true);
    const [validRepeatPassword, setValidRepeatPassword] = useState(true);

    const [incorrectMeilFormat, setIncorrectMeilFormat] = useState(false);
    const [incorrectRepeatPassowrd, setIncorrectRepeatPassowrd] = useState(false);

    // const [notificationMessage, setNotificationMessage] = useState('');
    // const [open, setOpen] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showServerError, setShowServerError] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [unregisteredUserError, setUnregisteredUserError] = useState(false);

    useEffect(() => {
        if (window.scrollTo) {
            window.scrollTo(0, 0);
        }
        setCurrentPath(props.path);
    }, []);

    useEffect(() => {
        if (Object.keys(props.userData).length) {
            props.navigate('/');
        }
    }, [props.userData])


    useEffect(() => {
        if (userId) {
            setValidUserId(true);
        }
        if (firstName) {
            setValidFirstName(true);
        }
        if (midName) {
            setValidMidName(true);
        }
        if (lastName) {
            setValidLastName(true);
        }
        if (email) {
            setValidEmail(true);
        }
        if (password) {
            setValidPassword(true);
        }
        if (repeatPassword) {
            setValidRepeatPassword(true);
        }
        if (password == repeatPassword) {
            setIncorrectRepeatPassowrd(false);
        }
        if ((meilRegex.test(String(email).toLowerCase()))) {
            setIncorrectMeilFormat(false);
        }

    }, [userId, firstName, midName, lastName, email, password, repeatPassword])


    const onRegister = (e: any) => {
        e.preventDefault();

        if (!userId) {
            setValidUserId(false);
            return;
        }

        if (!firstName) {
            setValidFirstName(false);
            return;
        }

        if (!midName) {
            setValidMidName(false);
            return;
        }

        if (!lastName) {
            setValidLastName(false);
            return;
        }

        if (!email) {
            setValidEmail(false);
            return;
        }

        if (!password) {
            setValidPassword(false);
            return;
        }

        if (password.length < 6) {
            setValidPassword(false);
            return;
        }

        if (!repeatPassword) {
            setValidRepeatPassword(false);
            return;
        }

        if (password !== repeatPassword) {
            setIncorrectRepeatPassowrd(true);
            return;
        }


        if (!(meilRegex.test(String(email).toLowerCase()))) {
            setIncorrectMeilFormat(true);
            return;
        }

        setShowLoading(true);
        axios.post(appConfig.userSignUp, {
            username: userId,
            midname: midName,
            email: email,
            first_name: firstName,
            last_name: lastName,
            password: password
        }).then(r => {
            // const signedUpUserData = r.data;
            if (r.data && r.data['errors'] === 'Unregistered user') {
                setShowLoading(false);
                setUnregisteredUserError(true);
            } else {
                setShowLoading(false);
                setShowSuccess(true);
            }

        }).catch(err => {
            console.error(err);
            setShowLoading(false);
            setShowServerError(true);
            setUnregisteredUserError(false);
        });
    }

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        // setOpen(false);
    };

    return (
        <div style={{ marginBottom: '5%' }}>
            <Container component="main" maxWidth="md">
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <div className={classes.paper}>
                        {
                            showLoading
                                ?
                                <div style={{ height: '30rem', display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                    <Loading style={{ width: 'unset', height: 'unset', textAlign: 'center' }} show={showLoading} />
                                </div>
                                :
                                showSuccess
                                    ?
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '42.5vh' }}>
                                        <CheckCircleRoundedIcon style={{ color: '#098363', width: '10rem', height: '10rem' }} />
                                        <Typography variant={"h6"} style={{ fontSize: 20, color: '#098363', fontWeight: 700 }}>
                                            {"Успешно създаден потребителски профил!"}
                                        </Typography>
                                        <Typography variant={"h6"} style={{ fontSize: 20, color: '#098363', fontWeight: 700 }}>
                                            {"Можете да влезете в системата чрез избор на меню Вход!"}
                                        </Typography>
                                    </div>
                                    :
                                    <>
                                        {
                                            showServerError
                                                ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <ErrorRoundedIcon style={{ color: 'red', width: '5rem', height: '5rem' }} />
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <Typography variant={"h6"} style={{ fontSize: 16, fontWeight: 700, textAlign: 'center', color: 'red' }}>
                                                            {"Възникна грешка!"}
                                                        </Typography>
                                                        <Typography variant={"h6"} style={{ fontSize: 16, fontWeight: 700, textAlign: 'center', color: 'red' }}>
                                                            {"Моля, опитайте отново!"}
                                                        </Typography>
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }
                                        {
                                            unregisteredUserError
                                                ?
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <ErrorRoundedIcon style={{ color: 'red', width: '5rem', height: '5rem' }} />
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <Typography variant={"h6"} style={{ fontSize: 16, fontWeight: 700, textAlign: 'center', color: 'red' }}>
                                                            {"Въведеният регистрационен номер не беше намерен в списъка с регистрирани правоспобни лица!"}
                                                        </Typography>
                                                        {/* <Typography variant={"h6"} style={{ fontSize: 16, fontWeight: 700, textAlign: 'center', color: 'red' }}>
                                                        {"Моля, опитайте отново!"}
                                                    </Typography> */}
                                                    </div>
                                                </div>
                                                :
                                                null

                                        }
                                        <Typography variant={"h6"} style={{ fontSize: 20, color: '#098363', fontWeight: 700, textAlign: 'center' }}>
                                            {"Създаване на потребителски профил"}
                                        </Typography>
                                        <form className={classes.form} noValidate>
                                            <Grid container spacing={4}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        value={userId}
                                                        onChange={(e) => setUserId(e.target.value)}
                                                        autoComplete="studentId"
                                                        name="studentId"
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        id="studentId"
                                                        label="Регистрационен номер"
                                                        error={!validUserId ? true : false}
                                                        helperText={!validUserId ? incorrectInput : ""}
                                                    />
                                                    <Typography variant="caption">
                                                        {"Моля, въведете Вашия номер от регистъра на "}<b>{"правоспособните физически лица"}</b> {"по кадастър"}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={4}>
                                                    <TextField
                                                        value={firstName}
                                                        onChange={(e) => setFirstName(e.target.value)}
                                                        autoComplete="fname"
                                                        name="firstName"
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        id="firstName"
                                                        label="Име"
                                                        error={!validFirstName ? true : false}
                                                        helperText={!validFirstName ? incorrectInput : ""}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <TextField
                                                        value={midName}
                                                        onChange={(e) => setMidName(e.target.value)}
                                                        autoComplete="midname"
                                                        name="midName"
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        id="midName"
                                                        label="Презиме"
                                                        error={!validMidName ? true : false}
                                                        helperText={!validMidName ? incorrectInput : ""}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <TextField
                                                        value={lastName}
                                                        onChange={(e) => setLastName(e.target.value)}
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        id="lastName"
                                                        label="Фамилия"
                                                        name="lastName"
                                                        autoComplete="lname"
                                                        error={!validLastName ? true : false}
                                                        helperText={!validLastName ? incorrectInput : ""}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <br />
                                            <Grid container spacing={4}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        id="studentsEmail"
                                                        label="E-mail адрес"
                                                        name="studentsEmail"
                                                        autoComplete="studentsEmail"
                                                        error={!validEmail || incorrectMeilFormat ? true : false}
                                                        helperText={!validEmail ? incorrectInput : incorrectMeilFormat ? incorrectEmail : ''}
                                                    />
                                                    <Typography variant="caption">
                                                        {"Моля, въведете коректен e-mail адрес за връзка с Вас"}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <br />
                                            <Grid item xs={12}>
                                                <TextField
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    name="password"
                                                    label="Парола"
                                                    type="password"
                                                    id="password"
                                                    autoComplete="current-password"
                                                    error={!validPassword ? true : false}
                                                    helperText={!validPassword ? `${incorrectInput} и трябва да бъде поне 6 символа` : "Паролата трябва да бъде поне 6 символа"}
                                                />
                                            </Grid>
                                            <br />
                                            <Grid item xs={12}>
                                                <TextField
                                                    value={repeatPassword}
                                                    onChange={(e) => setRepeatPassword(e.target.value)}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    name="repeatPassword"
                                                    label="Повторете паролата"
                                                    type="password"
                                                    id="repeatPassword"
                                                    autoComplete="repeat-password"
                                                    error={!validRepeatPassword || incorrectRepeatPassowrd ? true : false}
                                                    helperText={!validRepeatPassword ? incorrectInput : incorrectRepeatPassowrd ? incorrectRepeatPassowrdLabel : ''}
                                                />
                                            </Grid>
                                            <br />
                                            <div style={{ width: '100%', textAlign: 'center' }}>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.submit}
                                                    onClick={onRegister}
                                                    style={{ background: "linear-gradient(90deg, #0FC593 0%, #098363 100%)" }}
                                                >
                                                    <Typography variant={"h6"} style={{ fontSize: 16, fontWeight: 700 }}>

                                                        {"Регистрация"}
                                                    </Typography>

                                                </Button>
                                            </div>
                                        </form>
                                    </>
                        }
                    </div>
                </ThemeProvider>
            </Container>
        </div>
    );
}

export default SignUp;
