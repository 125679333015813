import * as React from 'react';
import { FunctionComponent, useEffect, useContext, useState } from 'react';
import { NavigateFn, RouteComponentProps } from '@reach/router';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import mini_logo_kiip from '../../assets/mini_logo_kiip.png';
import mini_logo_mgu from '../../assets/mini_logo_mgu.png';
import mini_logo_sgzb from '../../assets/mini_logo_sgzb.png';
import mini_logo_uaceg from '../../assets/mini_logo_uaceg.png';
import pdf_icon from '../../assets/pdf_icon.png';
import AccordionSummary from '../Accordion/AccordionSummary';
import Accordion from '../Accordion/Accordion';
import AccordionDetails from '../Accordion/AccordionDetails';
import { IUserData } from '../../context/AppUserContext';
import { ITestInfo } from '../../context/TestInfoContext';
import { appConfig } from '../../appConfig';
import { isTestAllowed } from '../utils';

interface Props extends RouteComponentProps {
    changeCurrentRoute: (currPath: string) => void;
    userData?: IUserData;
    testInfo?: ITestInfo;
}

const useStyles = makeStyles({
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(1.5)',
        color: '#0fc594'
    }
});

const DocumentsAndMaterials = (props: Props) => {
    const classes = useStyles();
    const bull = <span className={classes.bullet}>•</span>;
    const [, setExpanded] = useState<string | false>('panel1');
    const [userData, setUserData] = useState(props.userData || {});
    const [testInfo, setTestInfo] = useState<ITestInfo>(props.testInfo);

    useEffect(() => {
        if (window.scrollTo) {
            window.scrollTo(0, 0);
        }
        props.changeCurrentRoute('documents-and-materials');
    }, []);

    useEffect(() => {
        setUserData(props.userData);
    }, [props.userData]);

    useEffect(() => {
        setTestInfo(props.testInfo);
    }, [props.testInfo]);

    const handleChange = (panel: string) => (event: React.ChangeEvent<any>, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    // const openTest = (testType: string) => {
    //     if (Object.keys(userData).length) {
    //         if (testType === 'sample' || isTestAllowed(testInfo)) {
    //             window.open(`${window.location.origin}${window.location.pathname}?testType=${testType}#/test/`, '_blank');
    //         } else {
    //             alert(`Тестът ще бъде достъпен от дата ${testInfo.begin_date} 00:00 часа до дата ${testInfo.end_date} 00:00 часа`);
    //         }
    //     } else {
    //         alert("За да достъпите тест за проверка на знания, първо трябва да влезете в профила си!");
    //     }
    // }

    const openMaterial = (urlAddress: string) => {
        window.open(urlAddress, '_blank');
    }

    return (
        <Container component="div" maxWidth="lg">
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <div style={{ width: '100%', display: 'flex' }}>
                        <div style={{ marginTop: '4rem', paddingRight: '3rem' }}>
                            {/* <Accordion square expanded={true} onChange={() => { }}>
                                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" style={{ background: '#0fc594' }}>
                                    <Typography variant={"h6"} style={{ color: '#ffffff', fontSize: 14 }}>
                                        {"Тест, проверка на знанията"}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{ display: 'flex', marginTop: '0.3rem', cursor: 'pointer' }} onClick={(e) => openTest('sample')}>
                                        <div style={{ display: 'inline-block', marginRight: '0.3rem' }}>
                                            {bull}
                                        </div>
                                        <div style={{ display: 'inline-block' }}>
                                            <Typography variant={"body1"} style={{ fontSize: 14, fontWeight: 600 }}>
                                                {"Пробен тест"}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: '0.3rem', cursor: 'pointer' }} onClick={(e) => openTest('official')}>
                                        <div style={{ display: 'inline-block', marginRight: '0.3rem' }}>
                                            {bull}
                                        </div>
                                        <div style={{ display: 'inline-block' }}>
                                            <Typography variant={"body1"} style={{ fontSize: 14, fontWeight: 600 }}>
                                                {"Официален тест"}
                                            </Typography>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion> */}
                            <Accordion square expanded={true} onChange={handleChange('panel1')}>
                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                    <ArrowDropDownRoundedIcon style={{ color: '#ffffff' }} />
                                    <Typography variant={"h6"} style={{ color: '#ffffff', fontSize: 14 }}>
                                        {"Материали за обучение"}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{ display: 'flex', marginTop: '0.3rem' }}>
                                        <div style={{ display: 'inline-block', marginRight: '0.3rem' }}>
                                            {bull}
                                        </div>
                                        <div style={{ display: 'inline-block' }}>
                                            <Typography variant={"h6"} style={{ fontSize: 14 }}>
                                                {"Програма"}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: '0.3rem' }}>
                                        <div style={{ display: 'inline-block', marginRight: '0.3rem' }}>
                                            {bull}
                                        </div>
                                        <div style={{ display: 'inline-block' }}>
                                            <Typography variant={"body1"} style={{ fontSize: 14, fontWeight: 600 }}>
                                                {"Заповед за одобрение"}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: '0.3rem' }}>
                                        <div style={{ display: 'inline-block', marginRight: '0.3rem' }}>
                                            {bull}
                                        </div>
                                        <div style={{ display: 'inline-block' }}>
                                            <Typography variant={"body1"} style={{ fontSize: 14, fontWeight: 600 }}>
                                                {"Нормативна база и административен процес"}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: '2.0rem' }}>
                                        <div style={{ display: 'inline-block', margin: '0.3rem' }}>
                                            <Typography variant={"body1"} style={{ fontSize: 14, fontWeight: 600 }}>
                                                {"ЗКИР и подзаконовите нормативни документи"}
                                            </Typography>
                                        </div>
                                        <div style={{ display: 'inline-block', margin: '0.3rem' }}>
                                            <Typography variant={"body1"} style={{ fontSize: 14, fontWeight: 600 }}>
                                                {"Други закони и наредби"}
                                            </Typography>
                                        </div>
                                        <div style={{ display: 'inline-block', margin: '0.3rem' }}>
                                            <Typography variant={"body1"} style={{ fontSize: 14, fontWeight: 600 }}>
                                                {"Административни процеси"}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: '0.3rem' }}>
                                        <div style={{ display: 'inline-block', marginRight: '0.3rem' }}>
                                            {bull}
                                        </div>
                                        <div style={{ display: 'inline-block' }}>
                                            <Typography variant={"body1"} style={{ fontSize: 14, fontWeight: 600 }}>
                                                {"Kадастрална карта"}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: '2.0rem' }}>
                                        <div style={{ display: 'inline-block', margin: '0.3rem' }}>
                                            <Typography variant={"body1"} style={{ fontSize: 14, fontWeight: 600 }}>
                                                {"Изработване на кадастрална карта и кадастрални регистри"}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: '0.3rem' }}>
                                        <div style={{ display: 'inline-block', marginRight: '0.3rem' }}>
                                            {bull}
                                        </div>
                                        <div style={{ display: 'inline-block' }}>
                                            <Typography variant={"body1"} style={{ fontSize: 14, fontWeight: 600 }}>
                                                {"Изменения в кадастъра"}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: '2.0rem' }}>
                                        <div style={{ display: 'inline-block', margin: '0.3rem' }}>
                                            <Typography variant={"body1"} style={{ fontSize: 14, fontWeight: 600 }}>
                                                {"Източници на данни за обектите на кадастъра"}
                                            </Typography>
                                        </div>
                                        <div style={{ display: 'inline-block', margin: '0.3rem' }}>
                                            <Typography variant={"body1"} style={{ fontSize: 14, fontWeight: 600 }}>
                                                {"Изменение на данните за собствеността"}
                                            </Typography>
                                        </div>
                                        <div style={{ display: 'inline-block', margin: '0.3rem' }}>
                                            <Typography variant={"body1"} style={{ fontSize: 14, fontWeight: 600 }}>
                                                {"Изработване на проекти за изменение"}
                                            </Typography>
                                        </div>
                                        <div style={{ display: 'inline-block', margin: '0.3rem' }}>
                                            <Typography variant={"body1"} style={{ fontSize: 14, fontWeight: 600 }}>
                                                {"Изменение в данните за обектите на кадастъра, настъпили след одобряване на КККР"}
                                            </Typography>
                                        </div>
                                        <div style={{ display: 'inline-block', margin: '0.3rem' }}>
                                            <Typography variant={"body1"} style={{ fontSize: 14, fontWeight: 600 }}>
                                                {"Непълнота или грешка"}
                                            </Typography>
                                        </div>
                                        <div style={{ display: 'inline-block', margin: '0.3rem' }}>
                                            <Typography variant={"body1"} style={{ fontSize: 14, fontWeight: 600 }}>
                                                {"Явна фактическа грешка"}
                                            </Typography>
                                        </div>
                                        <div style={{ display: 'inline-block', margin: '0.3rem' }}>
                                            <Typography variant={"body1"} style={{ fontSize: 14, fontWeight: 600 }}>
                                                {"Изменение в данните на самостоятелните обекти"}
                                            </Typography>
                                        </div>
                                        <div style={{ display: 'inline-block', margin: '0.3rem' }}>
                                            <Typography variant={"body1"} style={{ fontSize: 14, fontWeight: 600 }}>
                                                {"Изработване на проекти при прилагане на ПУП"}
                                            </Typography>
                                        </div>
                                        <div style={{ display: 'inline-block', margin: '0.3rem' }}>
                                            <Typography variant={"body1"} style={{ fontSize: 14, fontWeight: 600 }}>
                                                {"Особени случаи на изработване на проекти"}
                                            </Typography>
                                        </div>
                                        <div style={{ display: 'inline-block', margin: '0.3rem' }}>
                                            <Typography variant={"body1"} style={{ fontSize: 14, fontWeight: 600 }}>
                                                {"Технически дейности при изработване на проекти"}
                                            </Typography>
                                        </div>

                                    </div>
                                    <div style={{ display: 'flex', marginTop: '0.3rem' }}>
                                        <div style={{ display: 'inline-block', marginRight: '0.3rem' }}>
                                            {bull}
                                        </div>
                                        <div style={{ display: 'inline-block' }}>
                                            <Typography variant={"body1"} style={{ fontSize: 14, fontWeight: 600 }}>
                                                {"Информационна система на кадастъра"}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: '2.0rem' }}>
                                        <div style={{ display: 'inline-block', margin: '0.3rem' }}>
                                            <Typography variant={"body1"} style={{ fontSize: 14, fontWeight: 600 }}>
                                                {"Кадастрална административна информационна система - КАИС"}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: '0.3rem' }}>
                                        <div style={{ display: 'inline-block', marginRight: '0.3rem' }}>
                                            {bull}
                                        </div>
                                        <div style={{ display: 'inline-block' }}>
                                            <Typography variant={"body1"} style={{ fontSize: 14, fontWeight: 600 }}>
                                                {"Съвременни технологии"}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: '2.0rem' }}>
                                        <div style={{ display: 'inline-block', margin: '0.3rem' }}>
                                            <Typography variant={"body1"} style={{ fontSize: 14, fontWeight: 600 }}>
                                                {"Инструменти и софтуер с приложение в кадастъра"}
                                            </Typography>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <Divider style={{ height: '75rem', marginTop: '2rem', marginBottom: '2rem' }} orientation={"vertical"} />
                    </div>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <div style={{}}>
                        <div style={{ marginTop: '4rem', paddingLeft: '1rem' }}>
                            <div>
                                <Typography variant={"body1"} style={{ fontWeight: 600 }}>
                                    {"Курсът на обучение и материалите са разработени по одобрена от АГКК програма за поддържане и повишаване квалификацията на правоспособните лица в съответствие с чл. 20, ал. 4 от ЗКИР. Програмата за обучение и предоставените материали са разработени със съдействието на:"}
                                </Typography>
                            </div>
                            <div style={{ width: '100%', height: '100%', marginTop: '2rem' }}>
                                <div style={{ display: 'flex', marginTop: '0.3rem' }}>
                                    <div style={{ width: '3rem', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={mini_logo_sgzb} alt="" />
                                    </div>
                                    <div style={{ marginLeft: '1.5rem', marginTop: '0.3rem' }}>
                                        <Typography variant={"h6"} style={{ textTransform: 'uppercase', fontSize: 16 }}>
                                            {"Съюз на геодезистите и земеустроителите в България"}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', marginTop: '0.3rem' }}>
                                    <div style={{ width: '3rem', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={mini_logo_uaceg} alt="" />
                                    </div>
                                    <div style={{ marginLeft: '1.5rem', marginTop: '0.3rem' }}>
                                        <Typography variant={"h6"} style={{ textTransform: 'uppercase', fontSize: 16 }}>
                                            {"Геодезически факултет, УАСГ"}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', marginTop: '0.3rem' }}>
                                    <div style={{ width: '3rem', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={mini_logo_mgu} alt="" />
                                    </div>
                                    <div style={{ marginLeft: '1.5rem', marginTop: '0.3rem' }}>
                                        <Typography variant={"h6"} style={{ textTransform: 'uppercase', fontSize: 16 }}>
                                            {"Катедра \"Маркшайдерство и Геодезия\" МГУ"}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', marginTop: '0.3rem' }}>
                                    <div style={{ width: '3rem', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={mini_logo_kiip} alt="" />
                                    </div>
                                    <div style={{ marginLeft: '1.5rem', marginTop: '0.3rem' }}>
                                        <Typography variant={"h6"} style={{ textTransform: 'uppercase', fontSize: 16 }}>
                                            {"Национална професионална секция \"Геодезия и приложна геодезия\", КИИП"}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            <Divider style={{ margin: '2rem 0' }} />
                            <div style={{ width: '100%', height: '100%', marginTop: '2rem', marginBottom: '2rem' }}>
                                <div style={{ display: 'flex', marginTop: '0.3rem', width: '85%', cursor: 'pointer' }} onClick={() => openMaterial(appConfig.materials.material_five)}>
                                    <div style={{ width: '3rem', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={pdf_icon} alt="" />
                                    </div>
                                    <div style={{ marginLeft: '1.5rem', display: 'flex', alignItems: 'center' }}>
                                        <Typography variant={"h6"} style={{ fontSize: 14, display: 'flex', alignItems: 'center' }}>
                                            {"Заповед на АГКК за одобряване на програма за обучение 21062021125739.pdf"}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', marginTop: '0.3rem', width: '85%', cursor: 'pointer' }} onClick={() => openMaterial(appConfig.materials.material_one)}>
                                    <div style={{ width: '3rem', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={pdf_icon} alt="" />
                                    </div>
                                    <div style={{ marginLeft: '1.5rem', display: 'flex', alignItems: 'center' }}>
                                        <Typography variant={"h6"} style={{ fontSize: 14, display: 'flex', alignItems: 'center' }}>
                                            {"Нормативна база на кадастъра тема 1.pdf"}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', marginTop: '0.3rem', width: '85%', cursor: 'pointer' }} onClick={() => openMaterial(appConfig.materials.material_two)}>
                                    <div style={{ width: '3rem', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={pdf_icon} alt="" />
                                    </div>
                                    <div style={{ marginLeft: '1.5rem', display: 'flex', alignItems: 'center' }}>
                                        <Typography variant={"h6"} style={{ fontSize: 14, display: 'flex', alignItems: 'center' }}>
                                            {"Изработване на КККР тема 2.pdf"}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', marginTop: '0.3rem', width: '85%', cursor: 'pointer' }} onClick={() => openMaterial(appConfig.materials.material_three)}>
                                    <div style={{ width: '3rem', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={pdf_icon} alt="" />
                                    </div>
                                    <div style={{ marginLeft: '1.5rem', display: 'flex', alignItems: 'center' }}>
                                        <Typography variant={"h6"} style={{ fontSize: 14, display: 'flex', alignItems: 'center' }}>
                                            {"Поддържане на КККР тема 3.pdf"}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', marginTop: '0.3rem', width: '85%', cursor: 'pointer' }} onClick={() => openMaterial(appConfig.materials.material_four)}>
                                    <div style={{ width: '3rem', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={pdf_icon} alt="" />
                                    </div>
                                    <div style={{ marginLeft: '1.5rem', display: 'flex', alignItems: 'center' }}>
                                        <Typography variant={"h6"} style={{ fontSize: 14, display: 'flex', alignItems: 'center' }}>
                                            {"ДИСТАНЦИОННИ МЕТОДИ ЗА ЦЕЛИТЕ НА КАДАСТЪРА.pdf"}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', marginTop: '0.3rem', width: '85%', cursor: 'pointer' }} onClick={() => openMaterial(appConfig.materials.material_six)}>
                                    <div style={{ width: '3rem', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={pdf_icon} alt="" />
                                    </div>
                                    <div style={{ marginLeft: '1.5rem', display: 'flex', alignItems: 'center' }}>
                                        <Typography variant={"h6"} style={{ fontSize: 14, display: 'flex', alignItems: 'center' }}>
                                            {"Кадастрална административна информационна система - КАИС"}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', marginTop: '0.3rem', width: '85%', cursor: 'pointer' }} onClick={() => openMaterial(appConfig.materials.material_seven)}>
                                    <div style={{ width: '3rem', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={pdf_icon} alt="" />
                                    </div>
                                    <div style={{ marginLeft: '1.5rem', display: 'flex', alignItems: 'center' }}>
                                        <Typography variant={"h6"} style={{ fontSize: 14, display: 'flex', alignItems: 'center' }}>
                                            {"Нормативна база и административен процес"}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', marginTop: '0.3rem', width: '85%', cursor: 'pointer' }} onClick={() => openMaterial(appConfig.materials.material_eight)}>
                                    <div style={{ width: '3rem', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={pdf_icon} alt="" />
                                    </div>
                                    <div style={{ marginLeft: '1.5rem', display: 'flex', alignItems: 'center' }}>
                                        <Typography variant={"h6"} style={{ fontSize: 14, display: 'flex', alignItems: 'center' }}>
                                            {"Съвременни технологии"}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
}

export default DocumentsAndMaterials;
