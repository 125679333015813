import * as React from 'react';
import { useContext, useState, useEffect } from 'react';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
// import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { headerStyles } from './headerStyles';
import HeaderButtons from './HeaderButtons';
import { IUserData } from '../../context/AppUserContext';



const useStyles = makeStyles(headerStyles);

interface Props {
    currentRoute: string;
    userData: IUserData;
}

const TopHeader = (props: Props) => {
    const classes = useStyles();
    const [currentRoute, setCurrentRoute] = useState(props.currentRoute);
    const [openDrawer, setOpenDrawer] = useState(false);


    // useEffect(() => {
    //     const data = getCookie('annual_geo_test');
    //     if (data) {
    //         const parsedData = JSON.parse(data);
    //         console.log(parsedData)
    //         setUserData(parsedData);
    //     }
    //     console.log(data);
    // }, [])


    useEffect(() => {
        setCurrentRoute(props.currentRoute);
    }, [props.currentRoute]);

    const handleDrawerOpen = () => {
        setOpenDrawer(!openDrawer);
    }

    const onDrawerClose = () => {
        if (openDrawer) {
            setOpenDrawer(false);
        }
    }

    return (
        <>
            {
                currentRoute !== 'test'
                    ?
                    <div className={classes.root}>
                        <AppBar position="static" style={{ height: '4rem' }} color={"transparent"}>
                            <Toolbar className={classes.desktopToolbar}>
                                <div className={classes.linksContainer}>
                                    <div className={classes.linkButtons}>
                                        <HeaderButtons currentRoute={props.currentRoute} userData={props.userData} onDrawerClose={onDrawerClose} />
                                    </div>
                                </div>
                            </Toolbar>
                            <Toolbar className={classes.mobileToolbar}>
                                <div style={{ flexGrow: 1 }}></div>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={handleDrawerOpen}
                                    edge="start"
                                    className={clsx(classes.menuButton, openDrawer && classes.hide)}
                                >
                                    <MenuIcon style={{ color: '#098363' }} />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                        <Drawer
                            className={classes.drawer}
                            anchor="right"
                            open={openDrawer}
                            onClose={() => { setOpenDrawer(false) }}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                        >
                            <HeaderButtons currentRoute={props.currentRoute} userData={props.userData} onDrawerClose={onDrawerClose} />
                        </Drawer>
                    </div>
                    :
                    null

            }
        </>

    );
}

export default TopHeader;
