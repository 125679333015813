import * as React from 'react';
import { Typography } from '@material-ui/core';
import ButtonsEpay from '../../assets/Button-ePay.bg.png';
import ButtonsPayPall from '../../assets/Button-PayPall.png';
import ButtonsMyPos from '../../assets/Button-myPos.png';

const CoursePayments = () => {

    return (
        <div style={{ paddingBottom: '3rem' }}>
            <div style={{}}>
                <Typography variant={'h6'}>
                    {`1) Чрез банков превод по посочената по-долу сметка или 2) on-line като използвате връзката с един от посочените оператори. При банков превод в  платежното нареждане попълнете`}
                </Typography>
                <Typography variant={'body1'} style={{ margin: '0.5rem' }}>
                    {`Получател: Съюз на геодезистите и земеустроителите в България`}
                </Typography>
                <Typography variant={'body1'} style={{ margin: '0.5rem' }}>
                    {`IBAN: BG14UBBS80021029388450`}
                </Typography>
                <Typography variant={'body1'} style={{ margin: '0.5rem' }}>
                    {`BIC: UBBSBGSF в ОБЕДИНЕНА БЪЛГАРСКА БАНКА АД`}
                </Typography>
                <Typography variant={'body1'} style={{ margin: '0.5rem' }}>
                    {`Основание: Такса за курс за правоспособни лица по кадастър,
                    трите имена на участника или рег. номер за правоспособност, ако наредителят се различава от участника.`}
                </Typography>
                <Typography variant={'body1'} style={{ margin: '0.5rem' }}>
                    {`Такса за участие в курса: 180 лв.`}
                </Typography>

                <br />
                {/* <Typography variant={'h6'}>
                    {`2. Он-лайн чрез:`}
                </Typography> */}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '1rem'
                }}>
                    <div>
                        <img src={ButtonsEpay}></img>
                    </div>
                    <form method="POST" action="https://www.epay.bg/v3main/paylogin" target="_blank">
                        <input type="hidden" name="MIN" value="5609825825"></input>
                        <input type="hidden" name="TOTAL" value="180.00"></input>
                        <input type="hidden" name="DESCR" value="Такса за участие в курс за повишаване на квалификацията на правоспособни лица по кадастър"></input>
                        <input type="hidden" name="URL_OK" value="https://www.epay.bg/v3main/front?p=thanks"></input>
                        <input type="hidden" name="URL_CANCEL" value="https://www.epay.bg/v3main/front?p=cancel"></input>
                        <input type="hidden" name="ENCODING" value="utf8"></input>
                        <input type="hidden" name="CHECKSUM" value="fc1b1afdc9885ff5790909af49a8ffa0c51617a3"></input>
                        <input type="image" src="https://online.datamax.bg/epaynow/a03.gif" alt="Плащане on-line"></input>
                    </form>
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '1rem'
                }}>
                    <div style={{ maxWidth: '9rem', height: '3rem' }}>
                        <img src={ButtonsPayPall} style={{ height: '100%', width: '100%' }}></img>
                    </div>
                    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
                        <input type="hidden" name="cmd" value="_s-xclick" />
                        <input type="hidden" name="hosted_button_id" value="LJFGNM5KTPCME" />
                        <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_paynowCC_LG.gif" name="submit" alt="PayPal - The safer, easier way to pay online!" />
                        <img alt="" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                    </form>
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '1rem'
                }}>
                    <div style={{ maxWidth: '9rem', height: '9rem' }}>
                        <img src={ButtonsMyPos} style={{ height: '100%', width: '100%' }}></img>
                    </div>
                    <form action="https://www.mypos.eu/vmp/btn/BYWLZUAJV7T33" method="post" target="_blank">
                        <button
                            type="submit"
                            value="submit"
                            style={{
                                minWidth: 140,
                                minHeight: 40,
                                padding: '0 10px',
                                border: 'none',
                                outline: 'none',
                                backgroundColor: '#1273eb',
                                fontFamily: 'Arial, Helvetica, sans-serif',
                                fontSize: 14,
                                color: '#ffffff',
                                textDecoration: 'none',
                                cursor: 'pointer',
                                borderRadius: 5
                            }}>
                            {"Платете сега"}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default CoursePayments;

