import * as React from 'react';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const AccordionSummary = withStyles({
    root: {
        borderRadius: 10,
        backgroundColor: '#0fc594',
        // backgroundColor: 'rgba(0, 0, 0, .03)',
        // borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

export default AccordionSummary;
