import * as React from 'react';
import { useState, useEffect } from 'react';
import { createStyles, Theme, makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Question from './Question';


const useStyles = makeStyles((theme: Theme) => createStyles({
    testButton: {
        borderColor: 'green',
        borderRadius: 30,
        borderStyle: 'solid',
        borderWidth: 1,
        cursor: 'pointer',
        [theme.breakpoints.up('sm')]: {
            margin: '1rem',
            padding: '0.5rem 2rem',
        },
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            padding: '0.5rem 1rem',
        },
    }
}));

interface Props {
    questionNumber?: number;
    question?: string;
    answers?: Array<string>;
    nextQuestion: (selectedAnswer: number, endTest?: boolean) => void;
    onAnswerChange: (answer: number) => void;
}

let currentAnswer = 0;

const Test = (props: Props) => {
    const classes = useStyles();

    const [questionNumber, setQuestionNumber] = useState(props.questionNumber || -1);
    const [question, setQuestion] = useState(props.question || '');
    const [answers, setAnswers] = useState(props.answers || []);

    useEffect(() => {
        setQuestionNumber(props.questionNumber);
        currentAnswer = 0;
    }, [props.questionNumber]);

    useEffect(() => {
        setQuestion(props.question);
        currentAnswer = 0;
    }, [props.question]);

    useEffect(() => {
        setAnswers(props.answers);
        currentAnswer = 0;
    }, [props.answers]);

    const handleChange = (answer: number) => {
        props.onAnswerChange(answer)
        currentAnswer = answer;
    }

    const nextQuestion = () => {
        props.nextQuestion(currentAnswer);
    }

    const endTest = () => {
        props.nextQuestion(currentAnswer, true);
    }

    return (
        <>
            <Typography variant={"h6"} style={{ fontSize: 30, color: '#098363', fontWeight: 700 }}>
                {"Тест за квалификация"}
            </Typography>
            <Divider style={{ background: '#0fc594' }} />

            <div>
                <Question
                    questionNumber={questionNumber}
                    question={question}
                    answers={answers}
                    handleChange={handleChange}
                />
            </div>

            <div>
                <Divider style={{ background: '#0fc594' }} />
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
                    <div className={classes.testButton} onClick={endTest}>
                        <Typography variant={"h6"} style={{ fontSize: 18, color: '#098363', fontWeight: 700 }}>
                            {"Прекрати"}
                        </Typography>
                    </div>
                    <div className={classes.testButton} onClick={nextQuestion}>
                        <Typography variant={"h6"} style={{ fontSize: 18, color: '#098363', fontWeight: 700 }}>
                            {
                                questionNumber === 10
                                    ?
                                    "Завърши"
                                    :
                                    "Следващ"
                            }
                        </Typography>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Test;
