import { createContext } from 'react';

export interface ITestInfo {
    begin_date: string;
    end_date: string;
    title: string;
    content: string;
}


const TestInfoContext = createContext<[ITestInfo, (testInfo: ITestInfo) => void]>([{} as ITestInfo, () => { }]);

export default TestInfoContext;
