import React, { useEffect, useState, FunctionComponent } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { green } from '@material-ui/core/colors';
import { RouteComponentProps } from '@reach/router';
import { theme } from '../../theming/theme';
import axios from 'axios';
import { Link } from '@reach/router';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { appConfig } from '../../appConfig';


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));


type Props = RouteComponentProps;

const ResetPassword = (props: Props) => {
    const classes = useStyles();

    const [studentsEmail, setStudentsEmail] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);

    const [showFailure, setShowFailure] = useState(false);

    const onReset = (e: any) => {
        e.preventDefault();

        axios.post(appConfig.requestResetEmail, {
            "email": studentsEmail,
            "redirect_url": `${window.location.origin}${window.location.pathname}#/set-password`
        }).then(r => {
            const resultData = r.data;
            setShowSuccess(true);
        }).catch(err => {
            setShowFailure(true);
            console.error(err);
        })
    }

    return (
        <div style={{ marginBottom: '13.8rem' }}>
            {
                showSuccess
                    ?
                    <>
                        <div className={classes.paper} style={{ textAlign: 'center' }}>
                            <Link to="/">
                                <div>
                                    <CheckCircleRoundedIcon style={{ width: '15rem', height: '15rem', color: green[500] }} />
                                </div>
                                <div style={{ color: 'blue', fontSize: 22 }}>
                                    {"Изпратен е имейл с информация за смяна на паролата!"}
                                </div>
                            </Link>
                        </div>
                    </>
                    :
                    showFailure ?
                        <>
                            <div className={classes.paper} style={{ textAlign: 'center' }}>
                                <Link to="/">
                                    <div>
                                        <CancelRoundedIcon style={{ width: '15rem', height: '15rem', color: 'rgb(255, 0, 0)' }} />
                                    </div>
                                    <div style={{ color: 'blue', fontSize: 22 }}>
                                        {"Неуспешен опит! Моля, опитайте по-късно!"}
                                    </div>
                                </Link>

                            </div>
                        </>
                        :
                        <ThemeProvider theme={theme}>
                            <Container component="main" maxWidth="xs">
                                <CssBaseline />
                                <div className={classes.paper}>
                                    <Avatar className={classes.avatar}>
                                        <LockOutlinedIcon />
                                    </Avatar>
                                    <Typography component="h1" variant="h5">
                                        {"Забравена парола"}
                                    </Typography>
                                    <form className={classes.form} noValidate>
                                        <TextField
                                            value={studentsEmail}
                                            onChange={(e) => setStudentsEmail(e.target.value)}
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="studentsEmail"
                                            label="Email адрес"
                                            name="studentsEmail"
                                            autoComplete="studentsEmail"
                                        />

                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}
                                            onClick={onReset}
                                        >
                                            {"Изпрати"}
                                        </Button>
                                        <Grid container justify="flex-end">
                                            <Grid item>
                                                <Link to="/">
                                                    {"Вече сте регистриран? Вход"}
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </div>
                            </Container>
                        </ThemeProvider>
            }
        </div>

    );
}

export default ResetPassword;
