import * as React from 'react';
import { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';
import AppUserContext from '../../context/AppUserContext';
import { showAllTextCss, isTestAllowed } from '../utils';
import { ITestInfo } from '../../context/TestInfoContext';


const useStyles = makeStyles({
    root: {
        minWidth: 275,
        borderRadius: 15,
        filter: 'drop-shadow(0 0 7.5px rgba(0,0,0,0.15))',
        backgroundColor: '#ffffff',
        borderColor: '#ffffff'
    },
    title: {
        fontSize: 18,
        fontWeight: 700,
        marginBottom: '1rem'
    },
    separator: {
        marginLeft: '1rem',
        marginRight: '1rem'
    }
});

interface Props {
    title: string;
    beginDate: string;
    endDate: string;
    description: string;
}


const OfficialTestInfo = (props: Props) => {
    const classes = useStyles();
    const [appUser, setAppUser] = useContext(AppUserContext);

    const [userData, setUserData] = useState(appUser || {});


    useEffect(() => {
        setUserData(appUser);
    }, [appUser]);

    const onTestOpen = () => {
        if (Object.keys(userData).length) {
            if (isTestAllowed({ begin_date: props.beginDate, end_date: props.endDate } as ITestInfo)) {
                window.open(`${window.location.origin}${window.location.pathname}?testType=official#/test/`, '_blank');
            } else {
                alert(`Тестът ще бъде достъпен от дата ${props.beginDate} 00:00 часа до дата ${props.beginDate} 00:00 часа! От меню Материали може да отворите Пробен тест.`);
            }
        } else {
            alert("За да достъпите тест за проверка на знания, първо трябва да влезете в профила си!");
        }
    }

    return (
        <Card className={classes.root} variant="outlined">
            <CardContent>
                <Typography className={classes.title} variant="h6" component="div">
                    {props.title}
                </Typography>
                <div style={{ display: 'flex', marginBottom: '1rem', alignItems: 'center' }}>
                    <Typography variant="body1" component="div" style={{ fontSize: 16, fontWeight: 400, textAlign: 'center' }}>
                        {'От Дата: '}
                        <span style={{ fontWeight: 700, color: '#098363' }}>
                            {props.beginDate}
                        </span>
                    </Typography>
                    <Typography className={classes.separator} variant="body2" component="p">
                        {'|'}
                    </Typography>
                    <Typography variant="body1" component="div" style={{ fontSize: 16, fontWeight: 400, textAlign: 'center' }}>
                        {'До Дата: '}
                        <span style={{ fontWeight: 700, color: '#098363' }}>
                            {props.endDate}
                        </span>
                    </Typography>
                </div>
                <Typography variant="body1" component="p" style={showAllTextCss}>
                    {props.description}
                </Typography>
                <Divider style={{ width: '100%', marginTop: '0.5rem', marginBottom: '0.5rem', background: '#E0F7F2' }} />
                <Typography variant="body1" component="p" style={showAllTextCss}>
                    {`Можете да положите теста в дните от `}
                    <span style={{
                        fontSize: 18,
                        fontWeight: 700,
                        color: '#098363'
                    }}>{`${props.beginDate} 00:00`}</span>
                    {` часа до `}
                    <span style={{
                        fontSize: 18,
                        fontWeight: 700,
                        color: '#098363'
                    }}>{`${props.endDate} 00:00`}</span>
                    {` часа`}
                </Typography>
                <div style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                    <Typography variant="body1" component="p" style={showAllTextCss}>
                        {`Всеки регистриран в платформата потребител може да стартира теста от `}
                        <span style={{
                            fontSize: 18,
                            color: 'rgb(68, 114, 196)',
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }} onClick={onTestOpen}>{"тук."}</span>
                    </Typography>
                </div>

            </CardContent>
            {/* <CardActions style={{ marginLeft: 'auto', position: 'relative' }}>
                <Button style={{ marginLeft: 'auto', padding: '4px 17px' }} size="small" onClick={showAllText}>
                    <Typography style={{ fontSize: 14, color: '#2E8D71' }} variant="body1" component="p">
                        {isShowAll ? "Скрий информация" : "Повече информация"}
                    </Typography>
                </Button>
                <div style={{ position: 'relative', width: '5%', height: '100%', cursor: 'pointer' }} onClick={showAllText}>
                    <div style={{
                        background: "linear-gradient(90deg, #098363 0%, #0FC593 100%)",
                        width: '3rem',
                        height: '3rem',
                        position: 'absolute',
                        right: -10,
                        bottom: 0,
                        top: -20,
                        borderTopLeftRadius: 15
                    }}>
                        <div style={{ position: 'relative' }}>
                            <ArrowForwardIosRoundedIcon style={isShowAll ? rotatedArrowStyle : arrowStyle} />
                        </div>
                    </div>

                </div>
            </CardActions> */}
        </Card>
    )
}

export default OfficialTestInfo;
