import * as React from 'react';
import { useContext, useState } from 'react';
import { Link } from '@reach/router'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MaterialsButton from '../assets/materials.svg';
import CourseButton from '../assets/courses.svg';
import CertificateButton from '../assets/certificate.svg';
import AppContext from '../context/AppContext';
import RouteContext from '../context/RouteContext';
import AppDialog from './AppDialog';
import { appConfig } from '../appConfig';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        minWidth: 100,
        borderRadius: 15,
        filter: 'drop-shadow(0 0 7.5px rgba(0,0,0,0.15))',
        backgroundColor: '#ffffff',
        borderColor: '#ffffff',
        margin: '1rem',
        height: '12rem',
        [theme.breakpoints.up('md')]: {
            maxWidth: '45%',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '60%',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '75%',
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '90%',
        },
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 18,
        fontWeight: 700,
        marginBottom: '1rem'
    },
    separator: {
        marginLeft: '1rem',
        marginRight: '1rem'
    },
    pos: {
        marginBottom: 12,
    },
    textContent: {
        [theme.breakpoints.up('md')]: {
            fontSize: 18,
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 16,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
        },
    }
}));

const MainMenus = () => {
    const classes = useStyles();

    const [openInfo, setOpenInfo] = useState(false);

    const [, setIsOpened] = useContext(AppContext);
    const [, setCurrentPath] = useContext(RouteContext);

    const login = () => {
        setIsOpened(true);
    }

    return (
        <div>
            <AppDialog handleClose={() => { setOpenInfo(false) }} isOpened={openInfo} content={
                <>
                    <iframe src={appConfig.commonConditionsUrl} style={{ width: '100%', height: '70vh' }} frameBorder={"0"} />
                </>
            } />
            <div style={{ width: '100%', textAlign: 'center' }}>
                <Typography variant={"h6"} style={{ color: 'rgb(68, 114, 196)', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { setOpenInfo(true) }}>{"Виж общите условия за използване на платформата за обучение"}</Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center', flexDirection: 'column', flexWrap: 'wrap' }}>
                <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                    <Card className={classes.root} variant="outlined">
                        <CardContent style={{ padding: 0 }}>
                            <Link to={'documents-and-materials'} style={{ textDecoration: 'none', color: '#808080' }} onClick={() => setCurrentPath("documents-and-materials")}>
                                <div style={{ display: 'flex', height: '100%', padding: '1rem' }}>
                                    <div style={{ margin: '1rem' }}>
                                        <img style={{ width: '6rem', height: '6rem' }} src={MaterialsButton} />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', margin: '0.5rem', flexDirection: 'column' }}>
                                        <Typography variant={"h6"} style={{ marginBottom: '0.5rem', fontSize: 18, color: '#098363' }}>{"Материали"}</Typography>
                                        <Typography variant={"body1"} className={classes.textContent}>{"Вижте програмата за обучение, одобрена от АГКК и материалите за обучение. Тук може да проверите знанията си"}</Typography>
                                    </div>
                                </div>
                            </Link>
                        </CardContent>
                    </Card>
                    <Card className={classes.root} variant="outlined">
                        <CardContent style={{ padding: 0 }}>
                            <Link to={'courses'} style={{ textDecoration: 'none', color: '#808080' }} onClick={() => setCurrentPath("courses")}>
                                <div style={{ display: 'flex', height: '100%', padding: '1rem' }}>
                                    <div style={{ margin: '1rem' }}>
                                        <img style={{ width: '6rem', height: '6rem' }} src={CourseButton} />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', margin: '0.5rem', flexDirection: 'column' }}>
                                        <Typography variant={"h6"} style={{ marginBottom: '0.5rem', fontSize: 18, color: '#098363' }}>{"Курсове"}</Typography>
                                        <Typography variant={"body1"} className={classes.textContent} style={{ width: '90%', marginRight: 'auto' }}>{"Регистрирайте се за предстоящи присъствени курсове за обучение"}</Typography>
                                    </div>
                                </div>
                            </Link>
                        </CardContent>
                    </Card>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Link to={'certificate'} style={{ textDecoration: 'none', color: '#808080' }} onClick={() => setCurrentPath("certificate")}>
                                <div style={{ display: 'flex', height: '100%', padding: '1rem' }}>
                                    <div style={{ margin: '1rem' }}>
                                        <img style={{ width: '6rem', height: '6rem' }} src={CertificateButton} />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', margin: '0.5rem', flexDirection: 'column' }}>
                                        <Typography variant={"h6"} style={{ marginBottom: '0.5rem', fontSize: 18, color: '#098363' }}>{"Удостоверение"}</Typography>
                                        <Typography variant={"body1"} className={classes.textContent}>{"Заяви и вземи удостоверение за успешно преминат курс за повишаване на квалификацията"}</Typography>
                                    </div>
                                </div>
                            </Link>
                        </CardContent>
                    </Card>
                    <Card className={classes.root} variant="outlined" style={{ cursor: 'pointer', color: '#808080' }}>
                        <CardContent onClick={login}>
                            <div style={{ display: 'flex', height: '100%', marginTop: '1rem' }}>
                                <div style={{ margin: '1rem' }}>
                                    <img style={{ width: '6rem', height: '6rem' }} src={MaterialsButton} />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', margin: '0.5rem', flexDirection: 'column' }}>
                                    <Typography variant={"h6"} style={{ marginBottom: '0.5rem', fontSize: 18, color: '#098363' }}>{"Вход/Регистрация"}</Typography>
                                    <Typography variant={"body1"} className={classes.textContent}>{"Изисква се регистрация за използвате възможността да присъствате на курс за обучение и онлайн тест"}</Typography>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default MainMenus;
