import * as React from 'react';
import { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import axios from 'axios';
import CourseInfo from './CourseInfo';
import { appConfig } from '../../appConfig';
import Loading from '../Loading/Loading';


const useStyles = makeStyles((theme: Theme) => createStyles({
    courseInfo: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            width: '75%',
        },
        [theme.breakpoints.down('md')]: {
            width: '75%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    }
}));


interface ICourse {
    title: string;
    content: string;
    place: string;
    create_date: string;
}

interface Props {
    width?: string | number;
    count?: number
}

const Courses = (props: Props) => {
    const classes = useStyles();

    const [latestCourse, setLatestCourse] = useState<ICourse | null>(null);
    const [coursesCount,] = useState(props.count || 1);
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        setShowLoading(true);
        axios.get(appConfig.coursesNews, {
            params: {
                count: coursesCount
            }
        }).then(result => {
            setShowLoading(false);
            const resultData = result.data;
            if (resultData.length) {
                const course = { ...resultData[0] };
                const currentDate = course['create_date'];
                const currentDateSplitted = currentDate.split('-');
                course['create_date'] = `${currentDateSplitted[2]}/${currentDateSplitted[1]}/${currentDateSplitted[0]}`;
                setLatestCourse(course);
            }
        }).catch(err => {
            setShowLoading(false);
            console.error(err);
        });
    }, []);

    return (
        <>
            {
                showLoading
                    ?
                    <div style={{ height: '30rem', display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                        <Loading style={{ width: 'unset', height: 'unset', textAlign: 'center' }} show={showLoading} />
                    </div>
                    :
                    <>
                        <div style={{ textAlign: 'center', marginBottom: '2rem', marginTop: '2rem' }}>
                            <Typography component="div" variant="h6" style={{ fontSize: 24, color: '#098363' }}>
                                {"Предстоящи курсове за обучение"}
                            </Typography>
                        </div>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div className={classes.courseInfo}>
                                {
                                    latestCourse
                                        ?
                                        <CourseInfo title={latestCourse.title} date={latestCourse.create_date} place={latestCourse.place} description={latestCourse.content} />
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </>
            }

        </>
    )
}

export default Courses;